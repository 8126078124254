/** @jsxImportSource theme-ui */
import React, { FC, memo } from 'react';

import ShowtimeHero from './ShowtimeHero';
import ShowtimeHeroConfirmation from './ShowtimeHeroConfirmation';

import { ShowtimeHeroConfig } from '../../../@types/modelTypes';
import ContainedRow from '../../common/layout/ContainedRow';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyShowtimeHeroWidget'>;
  step: string;
};

export const ShowtimeHeroWidget: FC<Props> = ({ widget, step }) => {
  const showtimeHeroConfig: ShowtimeHeroConfig = {
    useImage: widget.shape?.useImage,
    allowShowtimeChange: widget.shape?.allowShowtimeChange,
    attributeVariantShowtimeHero: widget.shape?.attributeVariantShowtimeHero,
    attributeVariantShowtimePicker:
      widget.shape?.attributeVariantShowtimePicker,
  };

  switch (step) {
    case 'confirmation':
      return (
        <ShowtimeHeroConfirmation showtimeHeroConfig={showtimeHeroConfig} />
      );
    default:
      return (
        <ContainedRow>
          <ShowtimeHero showtimeHeroConfig={showtimeHeroConfig} />
        </ContainedRow>
      );
  }
};

export default memo(ShowtimeHeroWidget);
