/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch } from 'react';

import { getQualifiedDeals } from './Helpers';

import { QuantitySelectorContext } from '../@types/actionTypes';
import { Config } from '../@types/configTypes';
import { ContentTickets } from '../@types/contentTypes';
import { TicketGroupsOrder } from '../@types/enums';
import {
  Deal,
  DealInTicketsStep,
  GetOrderedTicketsTypes,
  TicketTypeModel,
  TicketTypes,
  Tickets,
} from '../@types/modelTypes';
import TicketGroup from '../components/common/tickets/TicketGroup';
import { JOURNEY_TYPES } from '../constants';
import { actionCreators } from '../store/ActionCreators';

const getGroup = (
  id: string,
  tickets: TicketTypeModel[],
  classNames: string,
  heading: string,
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>,
  orderHasMaxTickets: boolean,
  showHeadings: boolean
) => {
  return (
    <TicketGroup
      key={id}
      orderHasMaxTickets={orderHasMaxTickets}
      classNames={classNames}
      dataTestId={`ticket-group-${id}`}
      tickets={tickets}
      handleTicketButtonClick={handleTicketButtonClick}
      ticketsHeading={showHeadings ? heading : undefined}
    />
  );
};

export const getOrderedTicketGroupsDazzler = (
  getOrderedTicketsVars: GetOrderedTicketsTypes,
  orderHasMaxTickets: boolean,
  memberTickets: TicketTypeModel[],
  packageTickets: TicketTypeModel[],
  standardTickets: TicketTypeModel[],
  cardPromoTickets: TicketTypeModel[],
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>
) => {
  const ticketGroupArray: React.ReactElement[] = [];

  const hasMemberTickets = memberTickets.length > 0;

  if (cardPromoTickets && cardPromoTickets.length > 0) {
    const cardPromoTicketsGrp = (classNames: string) =>
      getGroup(
        'card-promo-ticket-selector',
        cardPromoTickets,
        classNames,
        getOrderedTicketsVars.cardPaymentPromoTicketsHeading,
        handleTicketButtonClick,
        orderHasMaxTickets,
        getOrderedTicketsVars.showHeadings
      );
    ticketGroupArray.push(cardPromoTicketsGrp('ticket-group'));
    return ticketGroupArray;
  }

  const memberTicketsGrp = (classNames: string) =>
    getGroup(
      'member-ticket-selector',
      memberTickets,
      classNames,
      getOrderedTicketsVars.memberTicketsHeading,
      handleTicketButtonClick,
      orderHasMaxTickets,
      getOrderedTicketsVars.showHeadings
    );

  const packageTicketsGrp = (classNames: string) =>
    getGroup(
      'package-ticket-selector',
      packageTickets,
      classNames,
      getOrderedTicketsVars.packageTicketsHeading,
      handleTicketButtonClick,
      orderHasMaxTickets,
      getOrderedTicketsVars.showHeadings
    );

  const standardTicketsGrp = (classNames: string) =>
    getGroup(
      'standard-ticket-selector',
      standardTickets,
      classNames,
      getOrderedTicketsVars.standardTicketsHeading,
      handleTicketButtonClick,
      orderHasMaxTickets,
      getOrderedTicketsVars.showHeadings
    );

  const withHiddenTopBorder = (className: string) => {
    return `${className} ${hasMemberTickets ? 'hide-top-border' : ''}`;
  };

  const memberTicketClassname =
    getOrderedTicketsVars.enableMultipleLoyaltyCardTicketPurchase
      ? 'border-bottom-box-separator'
      : getOrderedTicketsVars.enableMemberTicketsHighlight
      ? 'ticket-group-highlight'
      : 'ticket-group';

  const packageTicketClassname =
    getOrderedTicketsVars.enablePackageTicketsHighlight
      ? 'ticket-group-highlight'
      : 'ticket-group';

  switch (getOrderedTicketsVars.ticketGroupsOrderStrategy) {
    case 'MEMBER_STANDARD_PACKAGE':
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        standardTicketsGrp(withHiddenTopBorder('ticket-group'))
      );
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      break;
    case 'PACKAGE_MEMBER_STANDARD':
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        standardTicketsGrp(withHiddenTopBorder('ticket-group'))
      );
      break;
    case 'PACKAGE_STANDARD_MEMBER':
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      break;
    case 'STANDARD_MEMBER_PACKAGE':
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        packageTicketsGrp(withHiddenTopBorder(packageTicketClassname))
      );
      break;
    case 'STANDARD_PACKAGE_MEMBER':
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      break;
    default:
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        packageTicketsGrp(withHiddenTopBorder(packageTicketClassname))
      );
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      break;
  }

  return ticketGroupArray;
};

export const getOrderedTicketGroups = (
  config: Config,
  orderHasMaxTickets: boolean,
  showHeadings: boolean,
  contentTickets: ContentTickets,
  memberTickets: TicketTypeModel[],
  packageTickets: TicketTypeModel[],
  standardTickets: TicketTypeModel[],
  cardPromoTickets: TicketTypeModel[],
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>
) => {
  const ticketGroupArray: React.ReactElement[] = [];

  const hasMemberTickets = memberTickets.length > 0;

  if (cardPromoTickets && cardPromoTickets.length > 0) {
    const cardPromoTicketsGrp = (classNames: string) =>
      getGroup(
        'card-promo-ticket-selector',
        cardPromoTickets,
        classNames,
        contentTickets.cardPaymentPromoTicketsHeading,
        handleTicketButtonClick,
        orderHasMaxTickets,
        showHeadings
      );
    ticketGroupArray.push(cardPromoTicketsGrp('ticket-group'));
    return ticketGroupArray;
  }

  const memberTicketsGrp = (classNames: string) =>
    getGroup(
      'member-ticket-selector',
      memberTickets,
      classNames,
      contentTickets.memberTicketsHeading,
      handleTicketButtonClick,
      orderHasMaxTickets,
      showHeadings
    );

  const packageTicketsGrp = (classNames: string) =>
    getGroup(
      'package-ticket-selector',
      packageTickets,
      classNames,
      contentTickets.packageTicketsHeading,
      handleTicketButtonClick,
      orderHasMaxTickets,
      showHeadings
    );

  const standardTicketsGrp = (classNames: string) =>
    getGroup(
      'standard-ticket-selector',
      standardTickets,
      classNames,
      contentTickets.standardTicketsHeading,
      handleTicketButtonClick,
      orderHasMaxTickets,
      showHeadings
    );

  const withHiddenTopBorder = (className: string) => {
    return `${className} ${hasMemberTickets ? 'hide-top-border' : ''}`;
  };

  const memberTicketClassname = config.tickets
    .enableMultipleLoyaltyCardTicketPurchase
    ? 'border-bottom-box-separator'
    : config.tickets.enableMemberTicketsHighlight
    ? 'ticket-group-highlight'
    : 'ticket-group';

  const packageTicketClassname = config.tickets.enablePackageTicketsHighlight
    ? 'ticket-group-highlight'
    : 'ticket-group';

  switch (config.tickets.ticketGroupsOrderStrategy) {
    case TicketGroupsOrder.MEMBER_STANDARD_PACKAGE:
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        standardTicketsGrp(withHiddenTopBorder('ticket-group'))
      );
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      break;
    case TicketGroupsOrder.PACKAGE_MEMBER_STANDARD:
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        standardTicketsGrp(withHiddenTopBorder('ticket-group'))
      );
      break;
    case TicketGroupsOrder.PACKAGE_STANDARD_MEMBER:
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      break;
    case TicketGroupsOrder.STANDARD_MEMBER_PACKAGE:
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        packageTicketsGrp(withHiddenTopBorder(packageTicketClassname))
      );
      break;
    case TicketGroupsOrder.STANDARD_PACKAGE_MEMBER:
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      break;
    default:
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        packageTicketsGrp(withHiddenTopBorder(packageTicketClassname))
      );
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      break;
  }

  return ticketGroupArray;
};

export const updateJourneyType = (
  tickets: Tickets,
  journeyType: string,
  dispatch: Dispatch<any>
) => {
  const isAllocatableSeatingTicketSelected = !!tickets.groupedTicketTypes.find(
    (x) =>
      x.isAllocatableSeating && x.ticketTypeModels.find((y) => y.quantity > 0)
  );

  if (
    !isAllocatableSeatingTicketSelected &&
    journeyType === JOURNEY_TYPES.TICKET_FIRST
  ) {
    dispatch(actionCreators.setJourneyType(JOURNEY_TYPES.TICKETS_ONLY));
  } else if (
    journeyType === JOURNEY_TYPES.TICKETS_ONLY &&
    isAllocatableSeatingTicketSelected
  ) {
    dispatch(actionCreators.setJourneyType(JOURNEY_TYPES.TICKET_FIRST));
  }
};

export const setDeals = (
  ticketTypeId: TicketTypeModel['id'],
  ticketTypes: TicketTypes,
  deals: Deal[],
  dealsInTicketsStep: DealInTicketsStep[],
  context: QuantitySelectorContext,
  dispatch: Dispatch<any>
) => {
  const { ticketTypeModels } = ticketTypes;

  const qualifiedDeals = getQualifiedDeals(
    ticketTypeId,
    ticketTypeModels,
    dealsInTicketsStep ?? [],
    deals,
    context
  );

  dispatch(actionCreators.setDealsInTicketsStep(qualifiedDeals));
  dispatch(actionCreators.setAppliedDealsWithDiscount([], 0));
};

export const getPackageTicketsDescription = (ticket: TicketTypeModel) => {
  return ticket.listPackageTicket
    .map((t, i) => {
      return (
        t.quantity +
        ' x ' +
        t.displayName +
        `${ticket.listPackageTicket.length - 1 > i ? ', ' : ''}`
      );
    })
    .join('');
};
