/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import ShowtimeHero from './ShowtimeHero';

import { ShowtimeHeroConfig } from '../../../@types/modelTypes';
import { selectConfig } from '../../../store/Selectors';

interface Props {
  barcodeUrl?: string;
  displayAddToCalendar?: boolean;
  displayBarcode?: boolean;
  displayConfirmationNumber?: boolean;
  displayMobileWallets?: boolean;
  orderId?: string;
}

const ShowtimeHeroLegacyWrapper: React.FC<Props> = ({
  barcodeUrl,
  displayAddToCalendar,
  displayBarcode,
  displayConfirmationNumber,
  displayMobileWallets,
  orderId,
}) => {
  const config = useSelector(selectConfig);
  const showtimeHeroConfig: ShowtimeHeroConfig = {
    allowShowtimeChange: config.allowShowtimeChange,
    attributeVariantShowtimeHero:
      config.showtimeAttributeIconsInShowtimeHeroFormat,
    attributeVariantShowtimePicker:
      config.showtimeAttributeIconsInShowtimePickerFormat,
  };

  return (
    <ShowtimeHero
      barcodeUrl={barcodeUrl}
      displayAddToCalendar={displayAddToCalendar}
      displayBarcode={displayBarcode}
      displayConfirmationNumber={displayConfirmationNumber}
      displayMobileWallets={displayMobileWallets}
      orderId={orderId}
      showtimeHeroConfig={showtimeHeroConfig}
    />
  );
};

export default ShowtimeHeroLegacyWrapper;
