export const trapFocus = (container) => {
  const focusableElementsString =
    'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])';
  let firstFocusableElement = null;
  let lastFocusableElement = null;

  const setFocusableElements = () => {
    const focusableElements = container.querySelectorAll(
      focusableElementsString
    );
    firstFocusableElement = focusableElements[0];
    lastFocusableElement = focusableElements[focusableElements.length - 1];
  };

  setFocusableElements(); // Initialize focusable elements

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      // Handle TAB press
      if (event.shiftKey && document.activeElement === firstFocusableElement) {
        // SHIFT + TAB on first element => move focus to last element
        lastFocusableElement.focus();
        event.preventDefault();
      } else if (
        !event.shiftKey &&
        document.activeElement === lastFocusableElement
      ) {
        // TAB on last element => move focus to first element
        firstFocusableElement.focus();
        event.preventDefault();
      }
    } else if (event.key === 'Escape') {
      // Handle ESC to close
      container.dispatchEvent(new Event('closeModal'));
    }
  };

  container.addEventListener('keydown', handleKeyDown);

  // Re-define focusable elements when the content changes
  const observer = new MutationObserver(() => setFocusableElements());
  observer.observe(container, {
    attributes: true,
    childList: true,
    subtree: true,
  });

  return () => {
    container.removeEventListener('keydown', handleKeyDown);
    observer.disconnect();
  };
};
