import React from 'react';

import ContainedRow from '../../common/layout/ContainedRow';
import ShowtimeHeroLegacyWrapper from '../../widgets/showtimehero/ShowtimeHeroLegacyWrapper';

const Warning: React.FC = () => {
  return (
    <div className='warning' data-testid='warning'>
      <ContainedRow>
        <ShowtimeHeroLegacyWrapper />
      </ContainedRow>
    </div>
  );
};

export default Warning;
