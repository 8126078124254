import { JourneyTypeConfig } from '../@types/modelTypes';
import {
  ROUTE_PARAMS,
  JOURNEY_TYPES,
  STEP_PATHS,
  JOURNEY_STEPS,
} from '../constants';

export const journeyTypeConfigs: { [key: string]: JourneyTypeConfig } = {
  [JOURNEY_TYPES.TICKET_FIRST]: {
    type: JOURNEY_TYPES.TICKET_FIRST,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: false,
    isTicketingJourney: true,
    canLogin: true,
    resolvesCinemaFromConfig: false,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: true,
    hasKioskStep: false,
    routes: {
      start: STEP_PATHS.STARTTICKETING,
      '-1': STEP_PATHS.WARNING,
      0: STEP_PATHS.LOGIN,
      1: STEP_PATHS.TICKETS,
      2: STEP_PATHS.SEATS,
      3: STEP_PATHS.PAYMENT,
      4: STEP_PATHS.CONFIRMATION,
      params: [
        ROUTE_PARAMS.EXTERNAL_CINEMA_ID,
        ROUTE_PARAMS.EXTERNAL_SESSION_ID,
      ],
    },
  },
  [JOURNEY_TYPES.TICKET_FIRST_KIOSK]: {
    type: JOURNEY_TYPES.TICKET_FIRST_KIOSK,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: false,
    isTicketingJourney: true,
    resolvesCinemaFromConfig: false,
    canLogin: true,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: true,
    hasKioskStep: true,
    routes: {
      start: STEP_PATHS.STARTTICKETING,
      '-1': STEP_PATHS.WARNING,
      0: STEP_PATHS.LOGIN,
      1: STEP_PATHS.TICKETS,
      2: STEP_PATHS.SEATS,
      3: STEP_PATHS.KIOSK,
      4: STEP_PATHS.PAYMENT,
      5: STEP_PATHS.CONFIRMATION,
      params: [
        ROUTE_PARAMS.EXTERNAL_CINEMA_ID,
        ROUTE_PARAMS.EXTERNAL_SESSION_ID,
      ],
    },
  },
  [JOURNEY_TYPES.SEAT_FIRST]: {
    type: JOURNEY_TYPES.SEAT_FIRST,
    isSeatsFirst: true,
    isConcessionsOnlyJourney: false,
    isTicketingJourney: true,
    resolvesCinemaFromConfig: false,
    canLogin: true,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: true,
    hasKioskStep: false,
    routes: {
      start: STEP_PATHS.STARTTICKETING,
      '-1': STEP_PATHS.WARNING,
      0: STEP_PATHS.LOGIN,
      1: STEP_PATHS.SEATS,
      2: STEP_PATHS.TICKETS,
      3: STEP_PATHS.PAYMENT,
      4: STEP_PATHS.CONFIRMATION,
      params: [
        ROUTE_PARAMS.EXTERNAL_CINEMA_ID,
        ROUTE_PARAMS.EXTERNAL_SESSION_ID,
      ],
    },
  },
  [JOURNEY_TYPES.SEAT_FIRST_KIOSK]: {
    type: JOURNEY_TYPES.SEAT_FIRST_KIOSK,
    isSeatsFirst: true,
    isConcessionsOnlyJourney: false,
    isTicketingJourney: true,
    resolvesCinemaFromConfig: false,
    canLogin: true,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: true,
    hasKioskStep: true,
    routes: {
      start: STEP_PATHS.STARTTICKETING,
      '-1': STEP_PATHS.WARNING,
      0: STEP_PATHS.LOGIN,
      1: STEP_PATHS.SEATS,
      2: STEP_PATHS.TICKETS,
      3: STEP_PATHS.KIOSK,
      4: STEP_PATHS.PAYMENT,
      5: STEP_PATHS.CONFIRMATION,
      params: [
        ROUTE_PARAMS.EXTERNAL_CINEMA_ID,
        ROUTE_PARAMS.EXTERNAL_SESSION_ID,
      ],
    },
  },
  [JOURNEY_TYPES.TICKETS_KIOSK]: {
    type: JOURNEY_TYPES.TICKETS_KIOSK,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: false,
    isTicketingJourney: true,
    resolvesCinemaFromConfig: false,
    canLogin: true,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: true,
    hasKioskStep: true,
    routes: {
      start: STEP_PATHS.STARTTICKETING,
      '-1': STEP_PATHS.WARNING,
      0: STEP_PATHS.LOGIN,
      1: STEP_PATHS.TICKETS,
      2: STEP_PATHS.KIOSK,
      3: STEP_PATHS.PAYMENT,
      4: STEP_PATHS.CONFIRMATION,
      params: [
        ROUTE_PARAMS.EXTERNAL_CINEMA_ID,
        ROUTE_PARAMS.EXTERNAL_SESSION_ID,
      ],
    },
  },
  [JOURNEY_TYPES.TICKETS_ONLY]: {
    type: JOURNEY_TYPES.TICKETS_ONLY,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: false,
    isTicketingJourney: true,
    resolvesCinemaFromConfig: false,
    canLogin: true,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: true,
    hasKioskStep: false,
    routes: {
      start: STEP_PATHS.STARTTICKETING,
      '-1': STEP_PATHS.WARNING,
      0: STEP_PATHS.LOGIN,
      1: STEP_PATHS.TICKETS,
      2: STEP_PATHS.PAYMENT,
      3: STEP_PATHS.CONFIRMATION,
      params: [
        ROUTE_PARAMS.EXTERNAL_CINEMA_ID,
        ROUTE_PARAMS.EXTERNAL_SESSION_ID,
      ],
    },
  },
  [JOURNEY_TYPES.CONCESSIONS_ONLY]: {
    type: JOURNEY_TYPES.CONCESSIONS_ONLY,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: true,
    resolvesCinemaFromConfig: false,
    canLogin: true,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: true,
    hasKioskStep: true,
    requiresLandingPageWhenNoCinemaParam: true,
    routes: {
      start: STEP_PATHS.STARTCONCESSIONS,
      '-1': `${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.LANDING}`,
      0: `${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.LOGIN}`,
      1: `${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.SELECT}`,
      2: `${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.PAYMENT}`,
      3: `${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.CONFIRMATION}`,
    },
  },
  [JOURNEY_TYPES.GIFTCARDS_ONLY]: {
    type: JOURNEY_TYPES.GIFTCARDS_ONLY,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: true,
    resolvesCinemaFromConfig: true,
    canLogin: false,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: false,
    hasKioskStep: false,
    routes: {
      start: `${STEP_PATHS.GIFTCARDS}`,
      1: `${STEP_PATHS.GIFTCARDS}/${STEP_PATHS.SELECT}`,
      2: `${STEP_PATHS.GIFTCARDS}/${STEP_PATHS.PAYMENT}`,
      3: `${STEP_PATHS.GIFTCARDS}/${STEP_PATHS.CONFIRMATION}`,
    },
  },
  [JOURNEY_TYPES.PHYSICAL_GIFTCARDS_ONLY]: {
    type: JOURNEY_TYPES.PHYSICAL_GIFTCARDS_ONLY,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: true,
    resolvesCinemaFromConfig: true,
    usesPhysicalGiftCardsConfigs: true,
    canLogin: false,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: false,
    hasKioskStep: false,
    routes: {
      start: `${STEP_PATHS.PHYSICALGIFTCARDS}`,
      1: `${STEP_PATHS.PHYSICALGIFTCARDS}/${STEP_PATHS.SELECT}`,
      2: `${STEP_PATHS.PHYSICALGIFTCARDS}/${STEP_PATHS.PAYMENT}`,
      3: `${STEP_PATHS.PHYSICALGIFTCARDS}/${STEP_PATHS.CONFIRMATION}`,
    },
  },
  [JOURNEY_TYPES.GIFTMEMBERSHIP_ONLY]: {
    type: JOURNEY_TYPES.GIFTMEMBERSHIP_ONLY,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: true,
    resolvesCinemaFromConfig: true,
    usesPhysicalGiftCardsConfigs: true,
    canLogin: false,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: false,
    hasKioskStep: false,
    routes: {
      start: `${STEP_PATHS.GIFTMEMBERSHIP}`,
      1: `${STEP_PATHS.GIFTMEMBERSHIP}/${STEP_PATHS.SELECT}`,
      2: `${STEP_PATHS.GIFTMEMBERSHIP}/${STEP_PATHS.PAYMENT}`,
      3: `${STEP_PATHS.GIFTMEMBERSHIP}/${STEP_PATHS.CONFIRMATION}`,
    },
  },
  [JOURNEY_TYPES.VOUCHERS_ONLY]: {
    type: JOURNEY_TYPES.VOUCHERS_ONLY,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: true,
    resolvesCinemaFromConfig: true,
    canLogin: false,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: false,
    hasKioskStep: false,
    routes: {
      start: `${STEP_PATHS.VOUCHERS}`,
      0: `${STEP_PATHS.VOUCHERS}/${STEP_PATHS.LOGIN}`,
      1: `${STEP_PATHS.VOUCHERS}/${STEP_PATHS.SELECT}`,
      2: `${STEP_PATHS.VOUCHERS}/${STEP_PATHS.PAYMENT}`,
      3: `${STEP_PATHS.VOUCHERS}/${STEP_PATHS.CONFIRMATION}`,
    },
  },
  [JOURNEY_TYPES.LOYALTY_SIGNUP_FREE]: {
    type: JOURNEY_TYPES.LOYALTY_SIGNUP_FREE,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: true,
    resolvesCinemaFromConfig: true,
    canLogin: false,
    hidesLoyaltyRedemption: true,
    hasStepZeroLogin: false,
    hasKioskStep: false,
    routes: {
      1: `${STEP_PATHS.JOIN}/${STEP_PATHS.DETAILS}`,
      2: `${STEP_PATHS.JOIN}/${STEP_PATHS.CONFIRMATION}`,
    },
  },
  [JOURNEY_TYPES.LOYALTY_SIGNUP_TIERED]: {
    type: JOURNEY_TYPES.LOYALTY_SIGNUP_TIERED,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: true,
    resolvesCinemaFromConfig: true,
    canLogin: false,
    hidesLoyaltyRedemption: true,
    hasStepZeroLogin: false,
    hasKioskStep: false,
    routes: {
      start: STEP_PATHS.SIGNUP,
      1: `${STEP_PATHS.SIGNUP}/${STEP_PATHS.SELECTTIER}`,
      2: `${STEP_PATHS.SIGNUP}/${STEP_PATHS.DETAILS}`,
      3: `${STEP_PATHS.SIGNUP}/${STEP_PATHS.PAYMENT}`,
      4: `${STEP_PATHS.SIGNUP}/${STEP_PATHS.CONFIRMATION}`,
    },
  },
  [JOURNEY_TYPES.LOYALTY_UPGRADE]: {
    type: JOURNEY_TYPES.LOYALTY_UPGRADE,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: true,
    resolvesCinemaFromConfig: true,
    canLogin: true,
    hidesLoyaltyRedemption: true,
    hasStepZeroLogin: true,
    hasKioskStep: false,
    routes: {
      start: `${STEP_PATHS.LOYALTY}/${STEP_PATHS.UPGRADE}`,
      0: `${STEP_PATHS.LOYALTY}/${STEP_PATHS.LOGIN}`,
      1: `${STEP_PATHS.LOYALTY}/${STEP_PATHS.SELECTTIER}`,
      2: `${STEP_PATHS.LOYALTY}/${STEP_PATHS.PAYMENT}`,
      3: `${STEP_PATHS.LOYALTY}/${STEP_PATHS.CONFIRMATION}`,
    },
  },
  [JOURNEY_TYPES.SEASON_PASS]: {
    type: JOURNEY_TYPES.SEASON_PASS,
    isSeatsFirst: false,
    isConcessionsOnlyJourney: false,
    canLogin: true,
    resolvesCinemaFromConfig: false,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: true,
    hasKioskStep: false,
    routes: {
      start: STEP_PATHS.STARTSEASONPASS,
      [JOURNEY_STEPS.SEASON_PASS
        .LOGIN]: `${STEP_PATHS.SEASONPASS}/${STEP_PATHS.LOGIN}`,
      [JOURNEY_STEPS.SEASON_PASS
        .SELECT]: `${STEP_PATHS.SEASONPASS}/${STEP_PATHS.SELECT}`,
      [JOURNEY_STEPS.SEASON_PASS
        .TICKETS]: `${STEP_PATHS.SEASONPASS}/${STEP_PATHS.TICKETS}`,
      [JOURNEY_STEPS.SEASON_PASS
        .SHOWTIMES]: `${STEP_PATHS.SEASONPASS}/${STEP_PATHS.SHOWTIMES}`,
      [JOURNEY_STEPS.SEASON_PASS
        .SEATS]: `${STEP_PATHS.SEASONPASS}/${STEP_PATHS.SEATS}`,
      [JOURNEY_STEPS.SEASON_PASS
        .PAYMENT]: `${STEP_PATHS.SEASONPASS}/${STEP_PATHS.PAYMENT}`,
      [JOURNEY_STEPS.SEASON_PASS
        .CONFIRMATION]: `${STEP_PATHS.SEASONPASS}/${STEP_PATHS.CONFIRMATION}`,
      params: [ROUTE_PARAMS.EXTERNAL_CINEMA_ID],
    },
  },
  [JOURNEY_TYPES.SEAT_PREVIEW]: {
    type: JOURNEY_TYPES.SEAT_PREVIEW,
    isSeatsFirst: true,
    isConcessionsOnlyJourney: false,
    canLogin: false,
    resolvesCinemaFromConfig: false,
    hidesLoyaltyRedemption: false,
    hasStepZeroLogin: false,
    hasKioskStep: false,
    routes: {
      start: STEP_PATHS.SEATPREVIEW,
      params: [
        ROUTE_PARAMS.EXTERNAL_CINEMA_ID,
        ROUTE_PARAMS.EXTERNAL_SESSION_ID,
      ],
    },
  },
};
