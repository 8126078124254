import { takeLeading, call, select, put } from 'redux-saga/effects';

import {
  ConfirmationModel,
  ConfirmationRequestModel,
  Customer,
  SpreedlyMakePaymentProps,
} from '../../@types/modelTypes';
import { PEACH_CODES } from '../../constants';
import { createConfirmation } from '../../services/Helpers';
import { getContentForError } from '../../services/PeachErrorResolver';
import backend from '../../services/RestUtilities';
import { actionCreators } from '../ActionCreators';
import {
  SPREEDLY_INITIATE_PAYMENT,
  SPREEDLY_COMPLETE3DSECURE,
} from '../Actions';
import {
  selectMakePaymentRequestModel,
  selectContent,
  selectCustomer,
  selectSelectedSeats,
  selectJourneyTypeConfig,
  selectJourneyType,
  selectBankCardAmount,
  selectGuestSessionToken,
  selectToken,
  selectDazzlerConfig,
} from '../Selectors';

/* eslint-disable @typescript-eslint/no-explicit-any */

function* spreedlyInitiatePaymentHandler(action: any): any {
  yield put(actionCreators.setLoading(true));

  const {
    makePaymentModelOverrideProps,
    callBackFunction,
    threeDsCallbackFunction,
    turnstile,
  }: SpreedlyMakePaymentProps = action.payload;

  const turnstileToken = yield turnstile?.getToken();

  const makePaymentModel = yield select(selectMakePaymentRequestModel);
  const content = yield select(selectContent);
  const selectedSeats = yield select(selectSelectedSeats);
  const journeyTypeConfig = yield select(selectJourneyTypeConfig);
  const journeyType = yield select(selectJourneyType);
  const bankCardAmount = yield select(selectBankCardAmount);
  const guestSessionToken = yield select(selectGuestSessionToken);
  const token = yield select(selectToken);
  const request = {
    ...makePaymentModel,
    ...makePaymentModelOverrideProps,
    bankCardAmount,
  };

  const initResponse = yield call(
    backend.post,
    'api/Spreedly/InitiatePayment',
    request,
    turnstileToken
  );

  if (!initResponse.ok) {
    yield put(actionCreators.setError(content.error.paymentModelErrorRichText));
    turnstile?.resetToken();
    yield put(actionCreators.setLoading(false));
    return;
  }

  const responseContent = initResponse.content;
  turnstile?.resetToken();
  if (responseContent.transactionToken && !!threeDsCallbackFunction) {
    yield threeDsCallbackFunction(responseContent.transactionToken);
  } else if (responseContent.peachCode === PEACH_CODES.noError) {
    yield put(actionCreators.setLoading(true));
    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const confirmationResponse = yield call(
      backend.post,
      `api/Confirmation/${responseContent.externalOrderId}?circuitId=${responseContent.circuitId}`,
      body
    );
    if (confirmationResponse.ok) {
      const customer: Customer = yield select(selectCustomer);
      const dazzlerConfig = yield select(selectDazzlerConfig);
      const data = confirmationResponse.content;
      data.dazzlerConfig = dazzlerConfig;
      const confirmation = createConfirmation(data, customer, journeyType);
      if (
        journeyTypeConfig.isConcessionsOnlyJourney &&
        selectedSeats &&
        confirmation.selectedSeats?.length !== selectedSeats.length
      ) {
        confirmation.selectedSeats = selectedSeats;
      }
      yield put(actionCreators.setSendAnalytics(true));
      yield put(actionCreators.setConfirmation(confirmation));
      yield put(
        actionCreators.setGuestSessionToken(
          confirmationResponse.content.guestSessionToken
        )
      );
    }
    yield put(actionCreators.setLoading(false));
  } else {
    yield put(
      actionCreators.setError(
        getContentForError(responseContent.peachCode, content),
        responseContent.peachCode
      )
    );
  }

  yield put(actionCreators.setLoading(false));
  if (callBackFunction) {
    yield callBackFunction();
  }
}

function* spreedlyCompleteThreeDSecurePaymentHandler(action: any): any {
  const guestSessionToken = yield select(selectGuestSessionToken);
  const makePaymentModel = yield select(selectMakePaymentRequestModel);
  const bankCardAmount = yield select(selectBankCardAmount);
  const journeyType = yield select(selectJourneyType);
  const content = yield select(selectContent);
  const selectedSeats = yield select(selectSelectedSeats);
  const token = yield select(selectToken);
  const journeyTypeConfig = yield select(selectJourneyTypeConfig);
  const {
    makePaymentModelOverrideProps,
    callBackFunction,
    threeDsCallbackFunction,
    transactionToken,
    threeDStatus,
    turnstile,
  }: SpreedlyMakePaymentProps = action.payload;

  const request = {
    ...makePaymentModel,
    ...makePaymentModelOverrideProps,
    bankCardAmount,
    transactionToken,
    threeDStatus,
  };
  const turnstileToken = yield turnstile?.getToken();
  const completeResponse = yield call(
    backend.post,
    'api/Spreedly/CompleteThreeDSecurePayment',
    request,
    turnstileToken
  );
  if (!completeResponse.ok) {
    yield put(actionCreators.setError(content.error.paymentModelErrorRichText));
    turnstile?.resetToken();
    yield put(actionCreators.setLoading(false));
    return;
  }

  const responseContent = completeResponse.content;
  turnstile?.resetToken();
  if (responseContent.transactionToken && !!threeDsCallbackFunction) {
    yield threeDsCallbackFunction(responseContent.transactionToken);
  } else if (responseContent.peachCode === PEACH_CODES.noError) {
    yield put(actionCreators.setLoading(true));
    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const confirmationResponse = yield call(
      backend.post,
      `api/Confirmation/${responseContent.externalOrderId}?circuitId=${responseContent.circuitId}`,
      body
    );
    if (confirmationResponse.ok) {
      const customer: Customer = yield select(selectCustomer);
      const dazzlerConfig = yield select(selectDazzlerConfig);
      const data = confirmationResponse.content as ConfirmationModel;
      data.dazzlerConfig = dazzlerConfig;
      const confirmation = createConfirmation(
        confirmationResponse.content,
        customer,
        journeyType
      );
      if (
        journeyTypeConfig.isConcessionsOnlyJourney &&
        selectedSeats &&
        confirmation.selectedSeats?.length !== selectedSeats.length
      ) {
        confirmation.selectedSeats = selectedSeats;
      }
      yield put(actionCreators.setSendAnalytics(true));
      yield put(actionCreators.setConfirmation(confirmation));
      yield put(
        actionCreators.setGuestSessionToken(
          confirmationResponse.content.guestSessionToken
        )
      );
    }
    yield put(actionCreators.setLoading(false));
  } else {
    yield put(
      actionCreators.setError(
        getContentForError(responseContent.peachCode, content),
        responseContent.peachCode
      )
    );
  }

  yield put(actionCreators.setLoading(false));
  if (callBackFunction) {
    yield callBackFunction();
  }
}

export function* spreedlyInitiatePaymentWatch() {
  yield takeLeading(
    [SPREEDLY_INITIATE_PAYMENT],
    spreedlyInitiatePaymentHandler
  );
}

export function* spreedlyCompleteThreeDSecurePaymentWatch() {
  yield takeLeading(
    [SPREEDLY_COMPLETE3DSECURE],
    spreedlyCompleteThreeDSecurePaymentHandler
  );
}
