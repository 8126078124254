import { Config } from '../@types/configTypes';
import {
  SeatMapRow,
  SeatMapSeat,
  SeatInGroup,
  SelectSeatsModel,
  SeatMapAreaCategory,
} from '../@types/modelTypes';
import { SEAT_TYPE } from '../constants';

export const findIndexOfSeat = (seat: SeatMapSeat, currentRow: SeatMapRow) => {
  for (let i = 0; i < currentRow.seats.length; i++) {
    if (currentRow.seats[i].id === seat.id) {
      return i;
    }
  }
  return -1;
};

export const isSeatAvailable = (
  seat: SeatMapSeat,
  selectedSeats: SeatMapSeat[]
) => {
  if (!seat) return false;
  return seat.isASeat && !seat.isUnavailable && !selectedSeats.includes(seat);
};

export const seatSelectionInProgress = (
  isSeatsFirstJourney: boolean,
  selectedSeats: SeatMapSeat[],
  seatsToAllocate: number
) => {
  return (
    !isSeatsFirstJourney &&
    (!selectedSeats || selectedSeats.length !== seatsToAllocate)
  );
};

export const getSeatGroup = (
  seat: SeatMapSeat,
  seatsModel: SelectSeatsModel
) => {
  function findSeatByName(seatName: string, areaCategoryCode: string) {
    let foundSeat: SeatMapSeat | undefined = undefined;
    for (
      let rIndex = 0;
      rIndex < seatsModel.seatsLayoutModel.rows.length;
      rIndex++
    ) {
      const row: SeatMapRow = seatsModel.seatsLayoutModel.rows[rIndex];
      if (foundSeat) break;
      for (let cIndex = 0; cIndex < row.seats.length; cIndex++) {
        if (
          row.seats[cIndex].seatName === seatName &&
          row.seats[cIndex].areaCategoryCode === areaCategoryCode
        ) {
          foundSeat = row.seats[cIndex];
          break;
        }
      }
    }
    return foundSeat;
  }

  const seatGroup: SeatMapSeat[] = [];
  const foundSeat = findSeatByName(seat.seatName, seat.areaCategoryCode);
  if (foundSeat) {
    if (foundSeat.seatsInGroup && foundSeat.seatsInGroupCount) {
      foundSeat.seatsInGroup.forEach((s: SeatInGroup) => {
        const sig = findSeatByName(s.seatName, seat.areaCategoryCode);
        if (sig) seatGroup.push(sig);
      });
    } else {
      seatGroup.push(seat);
    }
  }
  return seatGroup;
};

export const isSeatSelected = (
  seat: SeatMapSeat,
  selectedSeats: SeatMapSeat[]
) => {
  return !!selectedSeats.find((x) => x.seatName === seat.seatName);
};

export const getSeatsSelectedInArea = (
  seatAreaCode: SeatMapAreaCategory['areaCategoryCode'],
  selectedSeats: SeatMapSeat[]
) => {
  const totalSelected = [];
  selectedSeats.forEach((selectedSeat) => {
    if (selectedSeat.areaCategoryCode === seatAreaCode) {
      totalSelected.push(selectedSeat);
    }
  });
  return totalSelected.length;
};

export const getSeatsAvailableInArea = (
  seatAreaCode: SeatMapAreaCategory['areaCategoryCode'],
  config: Config,
  seatsModel: SelectSeatsModel,
  isSeatsFirstJourney: boolean
) => {
  if (isSeatsFirstJourney)
    return config.tickets.maxTicketsPerOrder
      ? config.tickets.maxTicketsPerOrder
      : seatsModel.seatsLayoutModel.maxSeatsToSelect;
  const areas = seatsModel.seatsLayoutModel.areaCategories;
  const area = areas.find((areas) => areas.areaCategoryCode === seatAreaCode);
  return area ? area.seatsToAllocate : 0;
};

export const getNumberSingleSeatsAvailable = (seatsModel: SelectSeatsModel) => {
  if (!seatsModel) return 0;
  let numberSingles = 0;
  seatsModel.seatsLayoutModel.rows.forEach((row) => {
    if (!row.isARow || row.seats.length === 0) return;
    row.seats.forEach((seat: SeatMapSeat) => {
      if (seat.seatsInGroupCount === 1 && !seat.isUnavailable) {
        numberSingles++;
      }
    });
  });
  return numberSingles;
};
export const getMaxNumberOfColumns = (seatsModel: SelectSeatsModel) => {
  if (!seatsModel) return 0;
  let numberOfCols = 0;
  for (const row of seatsModel.seatsLayoutModel.rows) {
    if (row.seats.length > 0 && row.seats.length > numberOfCols) {
      numberOfCols = row.seats.length;
    }
  }
  return numberOfCols;
};

export const getMinRowHeight = (numberOfCols: number, rowWidth: number) => {
  const heightWidthRatio = 0.25;
  let minHeight = 30;
  if (!rowWidth || !numberOfCols) return minHeight;
  minHeight = Math.round((rowWidth / numberOfCols) * heightWidthRatio);
  return minHeight;
};

export const getSeatNumberFontSize = (
  numberOfCols: number,
  rowWidth: number
) => {
  let seatNumberfontSize = 15;
  if (!rowWidth || !numberOfCols) return seatNumberfontSize;
  const fontSizeBasedOnColWidth = Math.round((rowWidth / numberOfCols) * 0.3);
  seatNumberfontSize =
    fontSizeBasedOnColWidth > seatNumberfontSize
      ? seatNumberfontSize
      : fontSizeBasedOnColWidth;
  return seatNumberfontSize;
};

export const shouldApplySingleSeatRule = (
  applySingleSeatRule: boolean,
  capacityExceedsSingleSeatRulePercentage: boolean
) => {
  if (!applySingleSeatRule) {
    return false;
  } else {
    return !capacityExceedsSingleSeatRulePercentage;
  }
};

export const hasCapacityExceededSingleSeatRuleThreshold = (
  singleSeatRuleThreshold: string | number | undefined,
  seatsModel: SelectSeatsModel
) => {
  const currentPercentage = getPercentageOfBookedSeats(seatsModel);

  return currentPercentage >= Number(singleSeatRuleThreshold);
};

const getPercentageOfBookedSeats = (seatsModel: SelectSeatsModel) => {
  if (!seatsModel) return 0;
  let currentPercentage = 0;
  let total = 0;
  let unavailable = 0;

  seatsModel.seatsLayoutModel.rows.forEach((row) => {
    if (!row.isARow || row.seats.length === 0) return;
    row.seats.forEach((seat) => {
      if (seat.id !== null && seat.isASeat) {
        total++;
        if (seat.isUnavailable) {
          unavailable++;
        }
      }
    });
  });

  currentPercentage = (unavailable / total) * 100;
  return currentPercentage;
};

export const seatCausingSingleSeatRuleToFire = (
  selectedSeats: SeatMapSeat[],
  seatsModel: SelectSeatsModel
) => {
  const seat = selectedSeats.find((seat) => {
    const area = seatsModel.seatsLayoutModel.areas.find(
      (a) => a.areaNumber === seat.areaId
    );
    if (area?.disableSingleSeatRule) {
      return false;
    }

    const rows = seatsModel.seatsLayoutModel.rows;
    const currentRow = rows.find((r) => {
      return r.physicalName === seat.rowPhysicalName;
    });

    if (currentRow) {
      const seatIndex = findIndexOfSeat(seat, currentRow);
      const seatBeforePrevious = currentRow.seats[seatIndex - 2];
      const seatPrevious = currentRow.seats[seatIndex - 1];
      const seatNext = currentRow.seats[seatIndex + 1];
      const seatAfterNext = currentRow.seats[seatIndex + 2];
      if (seat.type === SEAT_TYPE.WHEELCHAIR) return false;
      if (
        !isSeatAvailable(seatBeforePrevious, selectedSeats) &&
        isSeatAvailable(seatPrevious, selectedSeats)
      ) {
        return true;
      }

      if (
        isSeatAvailable(seatNext, selectedSeats) &&
        !isSeatAvailable(seatAfterNext, selectedSeats)
      ) {
        return true;
      }
    }
    return false;
  });
  return seat;
};
