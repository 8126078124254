/** @jsxImportSource theme-ui */
import React from 'react';

import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, Grid } from 'theme-ui';

import ShowtimeDate from './ShowtimeDate';

import { Time } from '../../../@types/modelTypes';
import {
  renderShowtimeAttributeList,
  timeFormat,
} from '../../../services/Helpers';
import {
  selectCartSummary,
  selectQueryString,
  selectRequestData,
  selectSelectedLanguageCulture,
  selectShowtimes,
} from '../../../store/Selectors';
interface ShowtimePickerProps {
  showtimeAttributeIconsInShowtimePickerFormat: string | undefined;
  showtimeChangeButtonAriaDescriptionText: string | undefined;
}

const ShowtimePicker: React.FC<ShowtimePickerProps> = ({
  showtimeAttributeIconsInShowtimePickerFormat,
  showtimeChangeButtonAriaDescriptionText,
}) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_cookies, setCookies, _removeCookies] = useCookies();
  const { externalCinemaId, externalSessionId } = useParams();

  const queryString = useSelector(selectQueryString);
  const requestData = useSelector(selectRequestData);
  const showtimes = useSelector(selectShowtimes);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const cartSummary = useSelector(selectCartSummary);

  const handleSessionChange = (externalId: string) => {
    const cookieOptions = {
      path: '/',
      expires: moment().add(5, 's').toDate(),
      secure: true,
      sameSite: true,
    };

    setCookies('requestData', requestData, cookieOptions);
    setCookies('isShowtimeChange', true, cookieOptions);

    const url = `/startticketing/${externalCinemaId}/${externalId}${queryString}`;
    window.location.href = url;
  };

  const renderShowtimeButton = (time: Time) => {
    const buttonDescriptionId = `description-${time.startDateTime}`;
    const isCurrentlySelected = time.startDateTime === cartSummary.sessionDate;

    const formattedTime = moment(time.startDateTime)
      .locale(selectedLanguageCulture)
      .format(timeFormat(selectedLanguageCulture));

    const ariaDescription = `${showtimeChangeButtonAriaDescriptionText} ${formattedTime}`;

    return (
      <Box
        key={`${time.externalSessionId}`}
        className='showtime-button-container'
        sx={{ my: 2, mx: 1 }}
      >
        {/* Button description for screen readers */}
        <span id={buttonDescriptionId} className='visually-hidden'>
          {ariaDescription}
        </span>

        <Button
          className='showtime-button btn-link'
          disabled={
            moment(time.startDateTime) < moment() ||
            time.externalSessionId === externalSessionId ||
            time.notBookable ||
            time.soldOut
          }
          onClick={() => handleSessionChange(time.externalSessionId)}
          aria-describedby={buttonDescriptionId}
          aria-selected={isCurrentlySelected ? 'true' : 'false'}
          aria-current={isCurrentlySelected ? 'time' : undefined}
          sx={{
            backgroundColor: 'secondaryButtonBackground',
            borderColor: 'accent',
            color: 'secondaryButtonColor',

            '&:disabled': {
              backgroundColor: 'secondaryButtonBackground',
              borderColor: 'accent',
              color: 'secondaryButtonColor',
            },

            '&:hover:enabled, &:focus:enabled, &:focus:hover:enabled': {
              backgroundColor: 'secondaryButtonBackgroundHover',
              borderColor: 'primary',
              color: 'secondaryButtonColorHover',
            },
          }}
        >
          <span className='showtime-hourminute'>{formattedTime}</span>
        </Button>
      </Box>
    );
  };

  const renderShowtimeRows = () => {
    return (
      showtimes &&
      showtimes.map((showtime) => (
        <Flex
          className='showtime-picker-date-row'
          key={`${showtime.date}`}
          sx={{ textAlign: 'left' }}
        >
          <ShowtimeDate date={showtime.date} />
          <Box
            className='showtime-picker-sessions-container'
            sx={{ width: '100%' }}
          >
            <div
              className='showtime-picker-sessions'
              sx={{
                borderTop: 'bodySeparator',
                pt: 6,
                pb: 4,
              }}
            >
              {showtime.experienceTypes.map((experienceType, index) => (
                <Box key={`experienceType${index}`} sx={{ ml: 4 }}>
                  {experienceType.experienceAttributes.length > 0 && (
                    <Box className='tiny' mb={2}>
                      {renderShowtimeAttributeList(
                        experienceType.experienceAttributes,
                        showtimeAttributeIconsInShowtimePickerFormat
                      )}
                    </Box>
                  )}
                  <Grid
                    className='showtime-buttons-container'
                    columns={3}
                    gap={0}
                  >
                    {experienceType.times.map((time) =>
                      renderShowtimeButton(time)
                    )}
                  </Grid>
                </Box>
              ))}
            </div>
          </Box>
        </Flex>
      ))
    );
  };

  return (
    <Box
      sx={{ px: 4 }}
      className='date-rows-container'
      data-testid='showtime-picker'
    >
      {renderShowtimeRows()}
    </Box>
  );
};

export default ShowtimePicker;
