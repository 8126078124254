/** @jsxImportSource theme-ui */
import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import messages from './intl';

import { GlobalState } from '../../../@types/modelTypes';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  displaySelectedSeatsNames,
  getDisplayDateAndTime,
} from '../../../services/Helpers';
import {
  selectCartSummary,
  selectContent,
  selectCustomer,
  selectSelectedLanguageCulture,
  selectSelectedSeatNames,
} from '../../../store/Selectors';
import CartSummaryItems from '../../common/cartsummary/CartSummaryItems';
import CartSummaryTotal from '../../common/cartsummary/CartSummaryTotal';
import { resolveTicketingCMSStringOrDefault } from '../helpers';
import globalMessages from '../intl';

interface Props {
  barcodeUrl?: string;
  displayBarcode?: boolean;
  displayDateTime?: boolean;
}

const ShowtimeHeroConcessions: React.FC<Props> = ({
  barcodeUrl,
  displayBarcode,
  displayDateTime,
}) => {
  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();
  const { formatMessage } = useIntl();

  const cartSummary = useSelector(selectCartSummary);
  const concessionsJourneyParentBookingRef = useSelector(
    (state: GlobalState) => state.concessionsJourneyParentBookingRef
  );
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const selectedSeatNames = useSelector(selectSelectedSeatNames);

  const displaySummary =
    (pathname.startsWith('/concessions/payment') && !isLargeScreenWidth) ||
    (pathname.startsWith('/concessions/confirmation') && !isLargeScreenWidth);

  const dot = <span className='dot-separator'>·</span>;

  if (
    !content ||
    !content.cartSummary ||
    !customer ||
    !cartSummary ||
    !cartSummary.sessionDate
  )
    return null;

  const backgroundImage = cartSummary.stillUrl
    ? cartSummary.stillUrl
    : cartSummary.posterUrl;

  const dateAndTime =
    cartSummary.sessionDate &&
    getDisplayDateAndTime(cartSummary.sessionDate, selectedLanguageCulture);

  return (
    <div
      className='showtime-hero'
      data-testid='showtime-hero-concessions'
      sx={{ mt: 4, textAlign: 'center' }}
    >
      <div className='showtime-hero-top'>
        <div
          className='backdrop'
          style={{ backgroundImage: `url(${backgroundImage}?width=770)` }}
        />
        <div className='caption'>
          <p className='tiny'>
            {cartSummary.rated && <span>{cartSummary.rated}</span>}
            {cartSummary.formattedDuration && cartSummary.rated && (
              <span>{dot}</span>
            )}
            {cartSummary.formattedDuration && (
              <span>{cartSummary.formattedDuration}</span>
            )}
          </p>

          <h2>{cartSummary.title}</h2>

          <p className='at'>
            {resolveTicketingCMSStringOrDefault(
              formatMessage(globalMessages.atLabel),
              content.cartSummary.atLabel
            )}{' '}
            <b>
              {cartSummary.theaterLocationName}
              {concessionsJourneyParentBookingRef && (
                <>
                  {dot}
                  {cartSummary.screen}
                </>
              )}
            </b>
          </p>

          {displayDateTime && (
            <p>
              {resolveTicketingCMSStringOrDefault(
                formatMessage(globalMessages.onLabel),
                content.cartSummary.onLabel
              )}{' '}
              {!!dateAndTime && (
                <b>
                  {dateAndTime.displayDate} - {dateAndTime.displayTime}
                </b>
              )}
            </p>
          )}

          {concessionsJourneyParentBookingRef && (
            <p>
              {resolveTicketingCMSStringOrDefault(
                formatMessage(messages.bookingRefLabel),
                content.cartSummary.bookingRefLabel
              )}{' '}
              <b>{concessionsJourneyParentBookingRef}</b>
            </p>
          )}

          {selectedSeatNames.length > 0 && (
            <p>
              {resolveTicketingCMSStringOrDefault(
                formatMessage(messages.yourSeatsLabel),
                content.cartSummary.yourSeatsLabel
              )}{' '}
              <b>{displaySelectedSeatsNames(selectedSeatNames)}</b>
            </p>
          )}
        </div>
      </div>

      {displayBarcode && (
        <div sx={{ mt: 5, px: 4 }}>
          <img src={barcodeUrl} alt='ticket barcode' />
        </div>
      )}

      {displaySummary && (
        <div className='cart-summary' sx={{ px: 4 }}>
          <CartSummaryItems />
          <CartSummaryTotal />
        </div>
      )}
    </div>
  );
};

export default ShowtimeHeroConcessions;
