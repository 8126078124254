/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import CardPaymentPromoBankCardInput from './CardPaymentPromoBankCardInput';
import { MemberCardInput } from './MemberCardInput';
import TicketPriceWithBookingFee from './TicketPriceWithBookingFee';
import TicketPriceWithBookingFeeTax from './TicketPriceWithBookingFeeTax';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import { TicketLongDescriptionSetting } from '../../../@types/enums';
import {
  Deal,
  DealInTicketsStep,
  TicketTypeModel,
} from '../../../@types/modelTypes';
import { getTicketDeals } from '../../../services/Helpers';
import { getPackageTicketsDescription } from '../../../services/TicketHelpers';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectTicketTypes,
  shouldDisplayTaxInTicketsPricing,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import QuantityButton from '../quantitybutton/QuantityButton';

interface Props {
  ticket: TicketTypeModel;
  onClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  disableAdd: boolean;
  disableRemove: boolean;
  isPackageTicket?: boolean;
  isCardPaymentPromotionTicket?: boolean;
  deals: Deal[];
  dealsInTicketsStep: DealInTicketsStep[];
  orderHasMaxTickets: boolean;
}

const TicketType: React.FC<Props> = ({
  ticket,
  onClick,
  disableAdd,
  disableRemove,
  isPackageTicket,
  isCardPaymentPromotionTicket,
  deals,
  dealsInTicketsStep,
  orderHasMaxTickets,
}) => {
  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const ticketTypes = useSelector(selectTicketTypes);
  const content = useSelector(selectContent);
  const ticketDeals = getTicketDeals(ticket.id, deals);
  const shouldDisplayTaxInTicketPricing = useSelector(
    shouldDisplayTaxInTicketsPricing
  );
  const [showMemberCardInput, setShowMemberCardInput] = useState(false);
  const [
    showCardPaymentPromoBankCardInput,
    setShowCardPaymentPromoBankCardInput,
  ] = useState(false);
  const [hasOneMemberTicket, setHasOneMemberTicket] = useState(false);

  useEffect(() => {
    // Check if there's at least one member ticket with quantity 1
    const hasOneMemberTicket = ticketTypes?.ticketTypeModels?.some(
      (ticketTypeModel: TicketTypeModel) =>
        ticketTypeModel.isMemberTicket && ticketTypeModel.quantity > 0
    );

    if (hasOneMemberTicket) {
      setHasOneMemberTicket(true);
    } else {
      setHasOneMemberTicket(false);
      setShowMemberCardInput(false);
    }

    setShowCardPaymentPromoBankCardInput(false);
  }, [ticketTypes]);

  const displayDealTextWithStatus = (ticketDeal: Deal, dealIndex: number) => {
    if (!dealsInTicketsStep) {
      return '';
    }

    const { title } = ticketDeal;

    const deal = dealsInTicketsStep.find((d) => d.title === title);

    const index = deal?.appliedByTicketTypes.findIndex(
      (tt) => tt.id === ticket.id
    );

    let dealStatusText = '';
    let isApplied = false;

    if (!deal || index === -1) {
      dealStatusText = ticketDeal.isConcessionRelated
        ? content.tickets.dealWithThisTicketText
        : content.tickets.dealAvailableText;
    } else if (deal.onlyValidForRequirements) {
      dealStatusText = content.tickets.dealAddMoreText.replace(
        '##TICKETQUANTITYREMAININGFORDEAL##',
        String(deal.ticketsQuantityStillToAdd)
      );
    } else {
      dealStatusText = content.tickets.dealAppliedText;
      isApplied = true;
    }

    return (
      <p
        className='deal-text-with-status tiny'
        key={`deal_with_text_status_${dealIndex}`}
        sx={{
          fontWeight: 'bold',
          textTransform: 'uppercase',
          svg: {
            '.a': {
              fill: 'websiteBackground',
            },
            '.b': {
              fill: 'primary',
            },
            '.c': {
              fill: 'primary',
            },
          },
        }}
      >
        {title} - {dealStatusText}
        {isApplied && <RadioSelectedSvg />}
      </p>
    );
  };

  const handleAddClick = () => {
    if (
      bookingData.isUserValidated &&
      config.tickets.enableMultipleLoyaltyCardTicketPurchase &&
      hasOneMemberTicket &&
      ticket.isMemberTicket
    ) {
      setShowMemberCardInput(true);
    } else if (isCardPaymentPromotionTicket) {
      setShowCardPaymentPromoBankCardInput(true);
    } else {
      onClick(ticket.id, 'add');
    }
  };

  const handleRemoveClick = () => {
    onClick(ticket.id, 'remove');
    if (
      bookingData.isUserValidated &&
      config.tickets.enableMultipleLoyaltyCardTicketPurchase &&
      !ticket.isMemberTicket
    ) {
      setShowMemberCardInput(false);
    }
  };

  const showTicketLongDesc =
    config.tickets.longDescription ===
      TicketLongDescriptionSetting.AllTickets ||
    (isPackageTicket &&
      config.tickets.longDescription !==
        TicketLongDescriptionSetting.NoDescription);

  return (
    <>
      <Flex
        className='ticket-type'
        data-testid={`ticket-type-${ticket.id}`}
        sx={{
          borderTop: 'bodySeparator',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex className='btn-container' sx={{ alignItems: 'center' }}>
          <QuantityButton
            context={'remove'}
            disabled={disableRemove}
            onClick={handleRemoveClick}
            ticketDisplayName={ticket.displayName}
          />
        </Flex>
        <Box
          sx={{ flexGrow: 1, px: 3, textWrap: 'balance', textAlign: 'center' }}
        >
          <h3
            title={ticket.displayName}
            sx={{ fontWeight: 'normal', fontSize: '1rem' }}
            tabIndex={0}
          >
            {ticket.displayName}
          </h3>

          {showTicketLongDesc && (
            <p className='tiny text-balance' data-testid='longdesc-label'>
              {ticket.longDescription}
            </p>
          )}

          {isPackageTicket &&
            config.tickets.longDescription ===
              TicketLongDescriptionSetting.NoDescription && (
              <p className='tiny text-balance' data-testid='includes-label'>
                {content.tickets.packageTicketsIncludesLabel}{' '}
                {getPackageTicketsDescription(ticket)}
              </p>
            )}

          {ticketDeals &&
            ticketDeals.map((td, i) => {
              return displayDealTextWithStatus(td, i);
            })}

          {shouldDisplayTaxInTicketPricing ? (
            <TicketPriceWithBookingFeeTax ticket={ticket} />
          ) : (
            <TicketPriceWithBookingFee ticket={ticket} />
          )}
        </Box>
        <Flex className='btn-container' sx={{ alignItems: 'center' }}>
          <QuantityButton
            context={'add'}
            disabled={disableAdd}
            onClick={handleAddClick}
            ticketDisplayName={ticket.displayName}
          />
        </Flex>
      </Flex>
      {showMemberCardInput && hasOneMemberTicket && !orderHasMaxTickets && (
        <MemberCardInput
          orderHasMaxTickets={orderHasMaxTickets}
          handleAddTicket={onClick}
          ticket={ticket}
        />
      )}
      {showCardPaymentPromoBankCardInput && !orderHasMaxTickets && (
        <CardPaymentPromoBankCardInput
          orderHasMaxTickets={orderHasMaxTickets}
          handleAddTicket={onClick}
          ticket={ticket}
        />
      )}
    </>
  );
};

export default TicketType;
