/* eslint-disable no-console */
/** @jsxImportSource theme-ui */
import React, { useState, useEffect, useCallback } from 'react';

import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid } from 'theme-ui';
import { v4 as uuidv4 } from 'uuid';
import { isLength } from 'validator';

import { PaymentProvidersEnum } from '../../../../@types/enums';
import {
  IntializePaymentProviderResponse,
  SpreedlyMakePaymentProps,
} from '../../../../@types/modelTypes';
import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import loadSpreedly from '../../../../scripts/loadSpreedly';
import loadSpreedlyCybersourceDeviceFingerprint from '../../../../scripts/loadSpreedlyCybersourceDeviceFingerprint';
import {
  getExpDateInvalidMessageKey,
  isExpiryDateValid,
} from '../../../../services/PaymentHelpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectBankCardAmountDisplayPrice,
  selectConfig,
  selectContent,
  selectCustomer,
  selectIsCustomerReadyForPayment,
  selectLoyaltyRecognitionNumber,
  selectPayment,
  selectSelectedPaymentProvider,
} from '../../../../store/Selectors';
import ActionButton from '../../../common/actionbutton/ActionButton';
import { resolveTicketingCMSStringOrDefault } from '../../helpers';
import globalMessages from '../../intl';
import ExpiryOptions from '../common/ExpiryOptions';
import LabelWithTooltip from '../common/LabelWithTooltip';
import messages from '../intl';

interface SpreedlyThreeDSLifecycle {
  start: () => void;
}
interface Props {
  isPageValidated?: boolean;
  handleValidatePage: () => void;
  initializePaymentProviderResponse:
    | IntializePaymentProviderResponse
    | undefined;
  setCreditCardType: (cardType: string) => void;
}

interface CybersourceDeviceFingerprintState {
  sessionId: string;
}

const SpreedlyPayment: React.FC<Props> = ({
  isPageValidated = false,
  handleValidatePage,
  initializePaymentProviderResponse,
  setCreditCardType,
}) => {
  const dispatch = useDispatch();
  const turnstile = useTurnstile();
  const { formatMessage } = useIntl();

  const config = useSelector(selectConfig);
  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const payment = useSelector(selectPayment);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const loyaltyRecognitionNumber = useSelector(selectLoyaltyRecognitionNumber);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const [loaded, setLoaded] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(false);
  const [cardNumberIsValid, setCardNumberIsValid] = useState(false);
  const [cardNumberIsValidated, setCardNumberIsValidated] = useState(false);
  const [cvvCodeIsValid, setCvvCodeIsValid] = useState<boolean>(false);
  const [cvvCodeIsValidated, setCvvCodeIsValidated] = useState<boolean>(false);
  const [cardType, setCardType] = useState('');
  const [cardToken, setCardToken] = useState('');
  const [browserInfo, setBrowserInfo] = useState('');
  const [transactionToken, setTransactionToken] = useState('');
  const [lifecycle, setLifecycle] = useState<SpreedlyThreeDSLifecycle | null>(
    null
  );
  const [showModal3DS, setShowModal3DS] = useState(false);
  const [statusEventAction, setStatusEventAction] = useState('');
  const [paymentStarted, setPaymentStarted] = useState(false);

  const fullAddressRequired = config.payment.requireAvsFullMatch;

  const [paymentState, setPaymentState] = useState({
    nameOnCard: payment?.nameOnCard ?? '',
    nameOnCardIsValid: payment?.nameOnCardIsValid ?? false,
    nameOnCardIsValidated: payment?.nameOnCardIsValidated ?? false,
    month: payment?.month ?? '',
    monthIsValid: payment?.monthIsValid ?? false,
    monthIsValidated: payment?.monthIsValidated ?? false,
    year: payment?.year ?? '',
    yearIsValid: payment?.yearIsValid ?? false,
    yearIsValidated: payment?.yearIsValidated ?? false,
    expiryDateIsValid: payment?.expiryDateIsValid ?? false,
    address: payment?.address ?? '',
    addressIsValid: !fullAddressRequired || payment?.addressIsValid,
    addressIsValidated: payment?.addressIsValidated ?? false,
    zipCode: payment?.zipCode ?? '',
    zipCodeIsValid: payment?.zipCodeIsValid ?? false,
    zipCodeIsValidated: payment?.zipCodeIsValidated ?? false,
  });

  const [
    cybersourceDeviceFingerprintState,
    setCybersourceDeviceFingerprintState,
  ] = useState<CybersourceDeviceFingerprintState>({
    sessionId: '',
  });

  // Load Spreedly controls from their API.
  useEffect(() => {
    if (loaded || selectedPaymentProvider !== PaymentProvidersEnum.SPREEDLY)
      return;

    dispatch(actionCreators.setLoading(true));

    loadSpreedly(() => {
      setLoaded(true);
    });
  }, [dispatch, loaded, selectedPaymentProvider]);

  // Handle InitializePaymentProvider and AdditionalData
  useEffect(() => {
    const deviceFingerprintingValues =
      initializePaymentProviderResponse?.APIURL;

    if (deviceFingerprintingValues) {
      const [orgId, merchantId] = deviceFingerprintingValues.split(';');
      const uniqueId: string = uuidv4();

      loadSpreedlyCybersourceDeviceFingerprint(
        (sessionId: string) => {
          setCybersourceDeviceFingerprintState({
            sessionId: sessionId,
          });
        },
        orgId,
        uniqueId,
        merchantId
      );
    }
  }, [
    initializePaymentProviderResponse?.APIURL,
    initializePaymentProviderResponse?.OrderId,
  ]);

  const showWarningMessage =
    (cardNumberIsValidated && !cardNumberIsValid) ||
    (paymentState.nameOnCardIsValidated && !paymentState.nameOnCardIsValid) ||
    (paymentState.monthIsValidated && !paymentState.monthIsValid) ||
    (paymentState.yearIsValidated && !paymentState.yearIsValid) ||
    (cvvCodeIsValidated && !cvvCodeIsValid) ||
    (paymentState.zipCodeIsValidated && !paymentState.zipCodeIsValid) ||
    (paymentState.monthIsValid &&
      paymentState.yearIsValid &&
      !paymentState.expiryDateIsValid) ||
    (fullAddressRequired &&
      paymentState.addressIsValidated &&
      !paymentState.addressIsValid);

  const isFormValid =
    paymentState.nameOnCardIsValid &&
    cardNumberIsValid &&
    cvvCodeIsValid &&
    paymentState.monthIsValid &&
    paymentState.yearIsValid &&
    paymentState.expiryDateIsValid &&
    paymentState.zipCodeIsValid &&
    (!fullAddressRequired || paymentState.addressIsValid) &&
    isCustomerReadyForPayment;

  const spreedly = window.Spreedly;

  const isNameFieldValid = (nameOnCard: string) => {
    return isLength(nameOnCard, { min: 1, max: 50 });
  };

  const handleNameOnCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameOnCard = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      nameOnCard,
      nameOnCardIsValid: isNameFieldValid(nameOnCard),
      nameOnCardIsValidated: true,
    });
  };

  const isMonthFieldValid = (month: string) => {
    return (
      !!month &&
      month !==
        resolveTicketingCMSStringOrDefault(
          formatMessage(messages.expiryMonthPlaceholder),
          content.payment.expiryMonthPlaceholder
        )
    );
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const month = e.currentTarget.value;

    setPaymentState({
      ...paymentState,
      expiryDateIsValid: isExpiryDateValid(month, paymentState.year),
      month,
      monthIsValid: isMonthFieldValid(month),
      monthIsValidated: true,
    });
    setMonthSelected(true);
  };

  const isYearFieldValid = (year: string) => {
    return (
      !!year &&
      year !==
        resolveTicketingCMSStringOrDefault(
          formatMessage(messages.expiryYearPlaceholder),
          content.payment.expiryYearPlaceholder
        )
    );
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      expiryDateIsValid: isExpiryDateValid(paymentState.month, year),
      year,
      yearIsValid: isYearFieldValid(year),
      yearIsValidated: true,
    });
    setYearSelected(true);
  };

  const isZipCodeFieldValid = (zipCode: string) => {
    return isLength(zipCode, { min: 1, max: 8 });
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const zipCode = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      zipCode,
      zipCodeIsValid: isZipCodeFieldValid(zipCode),
      zipCodeIsValidated: true,
    });
  };

  const isAddressFieldValid = (address: string) => {
    return isLength(address, { min: 1, max: 255 });
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      address,
      addressIsValid: isAddressFieldValid(address),
      addressIsValidated: true,
    });
  };

  const getValueOf = (feedback: string) => {
    return feedback
      ? content.payment[feedback as keyof typeof content.payment]
      : null;
  };

  const validateFields = () => {
    const {
      nameOnCardIsValid,
      nameOnCardIsValidated,
      monthIsValid,
      monthIsValidated,
      yearIsValid,
      yearIsValidated,
      zipCodeIsValid,
      zipCodeIsValidated,
      addressIsValid,
      addressIsValidated,
    } = paymentState;

    if (
      nameOnCardIsValidated &&
      monthIsValidated &&
      yearIsValidated &&
      zipCodeIsValidated &&
      addressIsValidated
    )
      return;

    setPaymentState({
      ...paymentState,
      nameOnCardIsValid: nameOnCardIsValidated && nameOnCardIsValid,
      nameOnCardIsValidated: true,
      monthIsValid: monthIsValidated && monthIsValid,
      monthIsValidated: true,
      yearIsValid: yearIsValidated && yearIsValid,
      yearIsValidated: true,
      zipCodeIsValid: zipCodeIsValidated && zipCodeIsValid,
      zipCodeIsValidated: true,
      addressIsValid:
        !fullAddressRequired || (addressIsValidated && addressIsValid),
      addressIsValidated: true,
    });
  };

  const handlePaymentClick = () => {
    spreedly.validate();

    if (!isPageValidated) {
      handleValidatePage();
    }

    validateFields();

    if (!isFormValid) return;
    setPaymentStarted(true);
    const requiredFields = {
      full_name: paymentState.nameOnCard,
      month: paymentState.month,
      year: paymentState.year,
      zip: paymentState.zipCode,
      email: customer.email,
      address1: paymentState.address,
    };
    spreedly.tokenizeCreditCard(requiredFields);
  };

  const resetPaymentOnError = () => {
    setPaymentStarted(false);
  };

  const start3dsWithToken = (transactionToken: string) => {
    setTransactionToken(transactionToken);
  };

  const makePayment = useCallback(
    async (spreedlyToken: string) => {
      if (!paymentState) return;

      const expiryMonth = paymentState.month.padStart(2, '0');
      const expiryYear = paymentState.year.slice(-2);

      dispatch(
        actionCreators.spreedlyInitiatePayment({
          makePaymentModelOverrideProps: {
            nameOnCard: paymentState.nameOnCard,
            cardType: cardType,
            cardToken: spreedlyToken,
            expiryDateMonth: expiryMonth,
            expiryDateYear: expiryYear,
            billingAddress: paymentState.address,
            billingPostal: paymentState.zipCode,
            loyaltyCardNumber: loyaltyRecognitionNumber,
            spreedlyCybersourceDeviceFingerprintId:
              cybersourceDeviceFingerprintState.sessionId,
            paymentProvider: PaymentProvidersEnum.SPREEDLY,
            spreedlyBrowserInfo: browserInfo,
          },
          callBackFunction: resetPaymentOnError,
          threeDsCallbackFunction: start3dsWithToken,
          turnstile,
        })
      );
    },
    [
      paymentState,
      dispatch,
      cardType,
      loyaltyRecognitionNumber,
      cybersourceDeviceFingerprintState.sessionId,
      browserInfo,
      turnstile,
    ]
  );

  const complete3DSecurePayment = useCallback(() => {
    const expiryMonth = paymentState.month.padStart(2, '0');
    const expiryYear = paymentState.year.slice(-2);

    dispatch(
      actionCreators.spreedlyCompleteThreeDSecurePayment({
        makePaymentModelOverrideProps: {
          nameOnCard: paymentState.nameOnCard,
          cardType: cardType,
          cardToken: cardToken,
          expiryDateMonth: expiryMonth,
          expiryDateYear: expiryYear,
          billingAddress: paymentState.address,
          billingPostal: paymentState.zipCode,
          loyaltyCardNumber: loyaltyRecognitionNumber,
          spreedlyCybersourceDeviceFingerprintId:
            cybersourceDeviceFingerprintState.sessionId,
          paymentProvider: PaymentProvidersEnum.SPREEDLY,
        },
        transactionToken: transactionToken,
        threeDStatus: statusEventAction,
        callBackFunction: resetPaymentOnError,
        turnstile,
      } as SpreedlyMakePaymentProps)
    );
  }, [
    cardToken,
    cardType,
    cybersourceDeviceFingerprintState.sessionId,
    dispatch,
    loyaltyRecognitionNumber,
    paymentState.address,
    paymentState.month,
    paymentState.nameOnCard,
    paymentState.year,
    paymentState.zipCode,
    transactionToken,
    turnstile,
    statusEventAction,
  ]);

  const setupSpreedly = useCallback(() => {
    if (!spreedly || !initializePaymentProviderResponse) return;
    const browser_size = '05';
    const acceptHeader =
      'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8';
    const browser_info = spreedly.ThreeDS.serialize(browser_size, acceptHeader);
    setBrowserInfo(browser_info);

    spreedly.init(initializePaymentProviderResponse.PublishKey, {
      numberEl: 'spreedly-number',
      cvvEl: 'spreedly-cvv',
    });

    spreedly.on('ready', function () {
      // card number
      spreedly.setPlaceholder('number', 'Card Number');
      spreedly.setTitle('number', 'Card Number');
      spreedly.setFieldType('number', 'tel');
      spreedly.setStyle(
        'number',
        'font-family: Arial, Helvetica, sans-serif; font-size: 15px; height: 27px; line-height: 1.5; padding: 0; width: 100%;'
      );
      spreedly.setNumberFormat('prettyFormat');
      // cvv
      spreedly.setPlaceholder('cvv', 'CVV');
      spreedly.setTitle('cvv', 'CVV Number');
      spreedly.setFieldType('cvv', 'tel');
      spreedly.setStyle(
        'cvv',
        'font-family: Arial, Helvetica, sans-serif; font-size: 15px; height: 27px; line-height: 1.5; padding: 0; width: 100%;'
      );
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    spreedly.on('errors', (errors: []) => {
      spreedly.reload();
    });

    spreedly.on(
      'paymentMethod',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      async (token: string, paymentMethod: any) => {
        setCardToken(token);
      }
    );

    spreedly.on(
      'fieldEvent',
      (
        name: string,
        type: string,
        activeEl: string,
        inputProperties: {
          cardType: string;
          validNumber: boolean;
          validCvv: boolean;
        }
      ) => {
        if (name === 'number' && type === 'input') {
          setCardType(inputProperties.cardType);
          setCreditCardType(inputProperties.cardType);

          if (inputProperties.validNumber) {
            setCardNumberIsValidated(true);
            setCardNumberIsValid(true);
          } else {
            setCardNumberIsValid(false);
          }
        }

        if (name === 'cvv' && type === 'input') {
          if (inputProperties.validCvv) {
            setCvvCodeIsValidated(true);
            setCvvCodeIsValid(true);
          } else {
            setCvvCodeIsValid(false);
          }
        }
      }
    );

    spreedly.on(
      'validation',
      (inputProperties: { validNumber: boolean; validCvv: boolean }) => {
        setCardNumberIsValidated(true);
        setCardNumberIsValid(inputProperties.validNumber);
        setCvvCodeIsValidated(true);
        setCvvCodeIsValid(inputProperties.validCvv);
      }
    );

    spreedly.on('3ds:status', (event: { action: string }) => {
      setStatusEventAction(event.action);
    });
  }, [initializePaymentProviderResponse, setCreditCardType, spreedly]);

  const createLifeCycle = useCallback(() => {
    if (!transactionToken) return;
    const lifecycle = new spreedly.ThreeDS.Lifecycle({
      environmentKey: initializePaymentProviderResponse?.PublishKey,
      hiddenIframeLocation: 'device-fingerprint',
      challengeIframeLocation: 'challenge',
      transactionToken: transactionToken,
      challengeIframeClasses: '',
    });
    setLifecycle(lifecycle);
  }, [
    initializePaymentProviderResponse?.PublishKey,
    spreedly,
    transactionToken,
  ]);

  // Make payment after tokenization.
  useEffect(() => {
    if (cardToken) {
      makePayment(cardToken);
    }
  }, [cardToken, makePayment]);

  // After spreedly controls are loaded, bind to their events.
  useEffect(() => {
    if (loaded) {
      setupSpreedly();
      dispatch(actionCreators.setLoading(false));
    }
  }, [dispatch, loaded, setupSpreedly]);

  // Respond to 3DS lifecycle events
  useEffect(() => {
    if (statusEventAction == '' || statusEventAction === 'device-fingerprint') {
      return;
    } else if (statusEventAction === 'error') {
      resetPaymentOnError();
    } else if (statusEventAction === 'challenge') {
      setShowModal3DS(true); //3005 need to show Direct Challenge modal, followed by succeeded
    } else if (
      statusEventAction === 'succeeded' ||
      statusEventAction === 'trigger-completion'
    ) {
      complete3DSecurePayment();
    }
  }, [complete3DSecurePayment, statusEventAction]);

  useEffect(() => {
    if (transactionToken) {
      createLifeCycle();
    }
  }, [createLifeCycle, transactionToken]);

  useEffect(() => {
    if (lifecycle) {
      lifecycle.start();
    }
  }, [lifecycle]);

  return (
    <div className='spreedly-payment' data-testid='spreedly-payment'>
      <div className='spreedly-form'>
        <Form noValidate>
          <Form.Group sx={{ mb: 4 }}>
            <LabelWithTooltip
              labelFor='cardNumber'
              helpText={resolveTicketingCMSStringOrDefault(
                formatMessage(messages.cardNumberHelpText),
                content.payment.cardNumberHelpText
              )}
            >
              {resolveTicketingCMSStringOrDefault(
                formatMessage(messages.cardNumberLabel),
                content.payment.cardNumberLabel
              )}
            </LabelWithTooltip>
            <div
              id='spreedly-number'
              className={classnames(
                'form-control',
                cardNumberIsValidated && cardNumberIsValid && 'is-valid',
                cardNumberIsValidated && !cardNumberIsValid && 'is-invalid'
              )}
            />
            <Form.Control.Feedback type='invalid'>
              {resolveTicketingCMSStringOrDefault(
                formatMessage(messages.cardNumberValidationText),
                content.payment.cardNumberValidationText
              )}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group sx={{ mb: 4 }}>
            <LabelWithTooltip
              labelFor='nameOnCard'
              helpText={resolveTicketingCMSStringOrDefault(
                formatMessage(messages.nameOnCardHelpText),
                content.payment.nameOnCardHelpText
              )}
            >
              {resolveTicketingCMSStringOrDefault(
                formatMessage(messages.nameOnCardLabel),
                content.payment.nameOnCardLabel
              )}
            </LabelWithTooltip>
            <Form.Control
              className={classnames(
                !paymentState.nameOnCardIsValid &&
                  paymentState.nameOnCardIsValidated &&
                  'is-invalid'
              )}
              type='text'
              placeholder={resolveTicketingCMSStringOrDefault(
                formatMessage(messages.nameOnCardPlaceholder),
                content.payment.nameOnCardPlaceHolder
              )}
              onChange={handleNameOnCardChange}
              value={paymentState.nameOnCard}
              required
              maxLength={50}
              isInvalid={
                paymentState.nameOnCardIsValidated &&
                !paymentState.nameOnCardIsValid
              }
              isValid={
                paymentState.nameOnCardIsValidated &&
                paymentState.nameOnCardIsValid
              }
              onBlur={() =>
                setPaymentState({
                  ...paymentState,
                  nameOnCardIsValidated: true,
                })
              }
              id='nameOnCard'
              name='nameOnCard'
            />
            <Form.Control.Feedback type='invalid'>
              {resolveTicketingCMSStringOrDefault(
                formatMessage(messages.nameOnCardValidationText),
                content.payment.nameOnCardValidationText
              )}
            </Form.Control.Feedback>
          </Form.Group>

          <Box>
            <Form.Group as={Box} sx={{ mb: 4 }}>
              <LabelWithTooltip
                labelFor='creditCardMonth'
                helpText={resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.expiryDateHelpText),
                  content.payment.expiryDateHelpText
                )}
              >
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.expiryDateLabel),
                  content.payment.expiryDateLabel
                )}
              </LabelWithTooltip>
              <Grid columns={2}>
                <Box>
                  <Form.Select
                    className={classnames(
                      'form-control',
                      !paymentState.monthIsValid &&
                        paymentState.monthIsValidated &&
                        'is-invalid',
                      !monthSelected && 'default-value'
                    )}
                    onChange={handleMonthChange}
                    value={paymentState.month}
                    required
                    isInvalid={
                      paymentState.monthIsValidated &&
                      !paymentState.monthIsValid
                    }
                    isValid={
                      paymentState.monthIsValidated && paymentState.monthIsValid
                    }
                    onBlur={() =>
                      setPaymentState({
                        ...paymentState,
                        monthIsValidated: true,
                      })
                    }
                    id='creditCardMonth'
                    name='creditCardMonth'
                  >
                    <ExpiryOptions type={'month'} />
                  </Form.Select>
                  <Form.Control.Feedback
                    className={classnames(
                      !paymentState.monthIsValid &&
                        paymentState.monthIsValidated &&
                        'is-invalid'
                    )}
                    type='invalid'
                  >
                    {getValueOf(
                      getExpDateInvalidMessageKey(paymentState, 'month')
                    )}
                  </Form.Control.Feedback>
                </Box>
                <Box>
                  <Form.Select
                    className={classnames(
                      'form-control',
                      !paymentState.yearIsValid &&
                        paymentState.yearIsValidated &&
                        'is-invalid',
                      !yearSelected && 'default-value'
                    )}
                    onChange={handleYearChange}
                    value={paymentState.year}
                    required
                    isInvalid={
                      paymentState.yearIsValidated && !paymentState.yearIsValid
                    }
                    isValid={
                      paymentState.yearIsValidated && paymentState.yearIsValid
                    }
                    onBlur={() =>
                      setPaymentState({
                        ...paymentState,
                        yearIsValidated: true,
                      })
                    }
                    id='creditCardYear'
                    name='creditCardYear'
                  >
                    <ExpiryOptions type={'year'} />
                  </Form.Select>
                  <Form.Control.Feedback
                    className={classnames(
                      !paymentState.monthIsValid &&
                        paymentState.monthIsValidated &&
                        'is-invalid'
                    )}
                    type='invalid'
                  >
                    {getValueOf(
                      getExpDateInvalidMessageKey(paymentState, 'year')
                    )}
                  </Form.Control.Feedback>
                </Box>
              </Grid>
            </Form.Group>
          </Box>

          {fullAddressRequired && (
            <Form.Group sx={{ mb: 4 }}>
              <LabelWithTooltip
                labelFor='address'
                helpText={resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.addressHelpText),
                  content.payment.addressHelpText
                )}
              >
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.addressLabel),
                  content.payment.addressLabel
                )}
              </LabelWithTooltip>
              <Form.Control
                className={classnames(
                  !paymentState.addressIsValid &&
                    paymentState.addressIsValidated &&
                    'is-invalid'
                )}
                type='text'
                placeholder={resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.addressPlaceholder),
                  content.payment.addressPlaceholder
                )}
                onChange={handleAddressChange}
                value={paymentState.address}
                required
                maxLength={255}
                isInvalid={
                  paymentState.addressIsValidated &&
                  !paymentState.addressIsValid
                }
                isValid={
                  paymentState.addressIsValidated && paymentState.addressIsValid
                }
                onBlur={() =>
                  setPaymentState({
                    ...paymentState,
                    addressIsValidated: true,
                  })
                }
                id='address'
                name='address'
              />
              <Form.Control.Feedback type='invalid'>
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.addressValidationText),
                  content.payment.addressValidationText
                )}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Grid columns={2}>
            <Form.Group as={Box} sx={{ mb: 4 }}>
              <LabelWithTooltip
                labelFor='securityNumber'
                helpText={resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.cvvHelpText),
                  content.payment.cvvHelpText
                )}
              >
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.cvvLabel),
                  content.payment.cvvLabel
                )}
              </LabelWithTooltip>
              <div
                id='spreedly-cvv'
                className={classnames(
                  'form-control',
                  cvvCodeIsValidated && cvvCodeIsValid && 'is-valid',
                  cvvCodeIsValidated && !cvvCodeIsValid && 'is-invalid'
                )}
              />
              <Form.Control.Feedback type='invalid'>
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.cvvValidationText),
                  content.payment.cvvValidationText
                )}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Box} sx={{ mb: 4 }}>
              <LabelWithTooltip
                labelFor='zipCode'
                helpText={resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.zipCodeHelpText),
                  content.payment.zipCodeHelpText
                )}
              >
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.zipCodeLabel),
                  content.payment.zipCodeLabel
                )}
              </LabelWithTooltip>
              <Form.Control
                className={classnames(
                  'spaced-letters',
                  !paymentState.zipCodeIsValid &&
                    paymentState.zipCodeIsValidated &&
                    'is-invalid'
                )}
                type='text'
                placeholder={resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.zipCodePlaceholder),
                  content.payment.zipCodePlaceholder
                )}
                onChange={handleZipCodeChange}
                value={paymentState.zipCode}
                required
                maxLength={8}
                isInvalid={
                  paymentState.zipCodeIsValidated &&
                  !paymentState.zipCodeIsValid
                }
                isValid={
                  paymentState.zipCodeIsValidated && paymentState.zipCodeIsValid
                }
                onBlur={() =>
                  setPaymentState({
                    ...paymentState,
                    zipCodeIsValidated: true,
                  })
                }
                id='zipCode'
                name='zipCode'
              />
              <Form.Control.Feedback type='invalid'>
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.zipCodeValidationText),
                  content.payment.zipCodeValidationText
                )}
              </Form.Control.Feedback>
            </Form.Group>
          </Grid>
        </Form>
      </div>
      <div id='device-fingerprint' className='hidden' />
      <div sx={{ display: showModal3DS ? 'block' : 'none' }}>
        <div id='challenge' />
      </div>
      <ActionButton
        onClick={handlePaymentClick}
        disabled={paymentStarted}
        showIcon
        warningMessage={resolveTicketingCMSStringOrDefault(
          formatMessage(globalMessages.formErrorsMessage),
          content.payment.formErrorsMessage
        )}
        warningTitle={resolveTicketingCMSStringOrDefault(
          formatMessage(globalMessages.formErrorsSubTitle),
          content.payment.formErrorsSubTitle
        )}
        showWarningMessage={showWarningMessage}
        mb={0}
        variant='primary'
      >
        {`${resolveTicketingCMSStringOrDefault(
          formatMessage(messages.submitText),
          content.payment.submitText
        )} ${priceToDisplay}`}
      </ActionButton>
    </div>
  );
};

export default SpreedlyPayment;
