/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import messages from './intl';

import {
  ConfirmationModel,
  ConfirmationRequestModel,
} from '../../../@types/modelTypes';
import { BARCODE_FORMATS, forceNewJourneyKey } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { createConfirmation } from '../../../services/Helpers';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectConfirmationData,
  selectCustomer,
  selectDazzlerConfig,
  selectGuestSessionToken,
  selectJourneyType,
  selectToken,
} from '../../../store/Selectors';
import AddToCalendar from '../../common/addtocalendar/AddToCalendar';
import ContainedRow from '../../common/layout/ContainedRow';
import MobileWallets from '../../common/mobilewallets/MobileWallets';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyOrderDetailsWidget'>;
}

const OrderDetails: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const guestSessionToken = useSelector(selectGuestSessionToken);
  const customer = useSelector(selectCustomer);
  const token = useSelector(selectToken);
  const journeyType = useSelector(selectJourneyType);
  const { orderId } = useParams();
  const [cookies] = useCookies();
  const confirmationData = useSelector(selectConfirmationData);
  const config = useSelector(selectConfig);
  const { isLargeScreenWidth } = useScreenWidth();
  const dazzlerConfig = useSelector(selectDazzlerConfig);

  const fetchData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const qs = buildQueryString({
      circuitId: searchParams.get('circuitId') ?? undefined,
      languageCulture: cookies.preferredLanguageCulture,
    });
    const url = `api/Confirmation/${orderId}${qs}`;

    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const response = await backend.post(url, body);
    if (response.ok) {
      const data = response.content as ConfirmationModel;
      data.dazzlerConfig = dazzlerConfig;
      const confirmation = createConfirmation(data, customer, journeyType);
      dispatch(actionCreators.setConfirmation(confirmation));
    }

    dispatch(actionCreators.setGuestSessionToken(undefined));

    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    searchParams,
    cookies.preferredLanguageCulture,
    orderId,
    guestSessionToken,
    token,
    dazzlerConfig,
    customer,
    journeyType,
  ]);
  useEffect(() => {
    if (!confirmationData || confirmationData.externalOrderId !== orderId) {
      fetchData();
    }

    window.sessionStorage.setItem(forceNewJourneyKey, '1');
  }, [confirmationData, orderId, fetchData]);

  if (!config || !confirmationData) return null;

  const shouldShowConfirmationNumber =
    config.currentCinema.showConfirmationId &&
    confirmationData.bookingConfirmationId !==
      confirmationData.codeUsedInBarcode &&
    !!confirmationData.bookingConfirmationId;
  const knownBarcodeFormat =
    BARCODE_FORMATS[config.currentCinema.barcodeFormat];
  const barcodeHeight = knownBarcodeFormat === 'QR' ? 150 : 60;
  const barcodeWidth = knownBarcodeFormat === 'QR' ? 150 : 264;
  const barcodeUrl = `/Image/GetBarcode/${confirmationData.externalOrderId}/${barcodeHeight}/${barcodeWidth}?circuitId=${config.circuitId}`;
  const shouldShowBarcode =
    knownBarcodeFormat !== 'None' && knownBarcodeFormat !== undefined;
  const showSummaryInShowtimeHero = !isLargeScreenWidth;

  return (
    <ContainedRow>
      <div sx={{ textAlign: 'center' }}>
        {shouldShowBarcode && !showSummaryInShowtimeHero && (
          <ContainedRow styles={{ mt: 6 }}>
            <img src={barcodeUrl} alt='ticket barcode' />
          </ContainedRow>
        )}

        {!showSummaryInShowtimeHero && (
          <>
            <MobileWallets orderId={orderId} />
            <AddToCalendar
              displayConfirmationNumber={shouldShowConfirmationNumber}
              locationCinemaName={config.currentCinema.title}
            />
          </>
        )}
        <h2 sx={{ mt: 7 }}>
          {formatMessage(messages.bookingReferenceSubHeading)}
        </h2>
        {confirmationData.codeUsedInBarcode && (
          <p>{confirmationData.codeUsedInBarcode}</p>
        )}
        {config.currentCinema.showConfirmationId &&
          confirmationData.bookingConfirmationId !==
            confirmationData.codeUsedInBarcode && (
            <>
              <h2 sx={{ mt: 7 }}>
                {formatMessage(messages.confirmationIdSubHeading)}
              </h2>
              {shouldShowConfirmationNumber && (
                <p>{confirmationData.bookingConfirmationId}</p>
              )}
            </>
          )}
        <h2 sx={{ mt: 7 }}>{formatMessage(messages.yourTicketsSubHeading)}</h2>
        {customer.email && <p>{customer.email}</p>}
      </div>
    </ContainedRow>
  );
};

export default OrderDetails;
