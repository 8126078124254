/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { ThemeUIStyleObject } from 'theme-ui';

import { resolveSvg } from './SeatSvgResolver';

import {
  SeatButtonStyle,
  SeatMapSeat,
  SeatCustomStyle,
  SeatConfig,
} from '../../../@types/modelTypes';
import { SEAT_STATUS, SEAT_STYLE, SEAT_TYPE } from '../../../constants';
import {
  getSeatTypeConfigs,
  getSeatButtonTitle,
  getSeatStatusConfig,
} from '../../../services/SeatHelpers';
import {
  selectConfig,
  selectContent,
  selectIsSeatPreview,
  selectNumberOfSeatsToSelect,
  selectSeatsModel,
  selectSelectedLanguageCulture,
  selectSelectedSeats,
} from '../../../store/Selectors';

const minimumFontSizeToDisplay = 8;
interface Props {
  areaProhibited: boolean;
  seatButtonStyle: SeatButtonStyle;
  forceSeatText: boolean;
  handleSeatClick: (seat: SeatMapSeat) => void;
  isSelected: boolean;
  isSelectedRow: boolean;
  isThreeSeatSofa: boolean;
  seat: SeatMapSeat;
  isCoordinateBased: boolean;
  overrideSoldAsGroup: boolean;
  seatUnavailable: boolean;
}

const SeatButton: React.FC<Props> = ({
  areaProhibited,
  seatButtonStyle,
  forceSeatText: forceSeatNumbers,
  handleSeatClick,
  isSelected,
  isSelectedRow,
  isThreeSeatSofa,
  seat,
  isCoordinateBased,
  overrideSoldAsGroup,
  seatUnavailable,
}) => {
  const selectedSeats = useSelector(selectSelectedSeats);
  const seatsModel = useSelector(selectSeatsModel);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const numberOfSeatsToSelect = useSelector(selectNumberOfSeatsToSelect);
  const isSeatPreview = useSelector(selectIsSeatPreview);
  const disableWheelchairSelection =
    config.seats.disableWheelchairSelection &&
    seat.type === SEAT_TYPE.WHEELCHAIR;
  const shouldDisableSeatGroup =
    seat.soldAsGroup && !overrideSoldAsGroup && numberOfSeatsToSelect === 1;
  const isSeatSelectionDisabled =
    seat.type == SEAT_TYPE.NON_BOOKABLE_ELEMENT ||
    disableWheelchairSelection ||
    areaProhibited ||
    shouldDisableSeatGroup;
  const seatStatusConfig = getSeatStatusConfig(seat, isSelected);
  const seatConfig: SeatConfig | undefined = getSeatTypeConfigs(
    isCoordinateBased
  ).find((x: SeatConfig) => x.type === seat.type && x.style === seat.style);

  if (!seatConfig || !selectedSeats) {
    return null;
  }

  const seatCustomStyle: SeatCustomStyle | undefined =
    seatsModel.availableSeatTypes.customSeatStyles?.find(
      (x) => x.id == seat.customStyleId
    );

  const shouldShowSeatText =
    (forceSeatNumbers || isSelectedRow || isSelected) &&
    seatConfig.shouldShowSeatId &&
    !areaProhibited &&
    (seatCustomStyle ? seatCustomStyle.showSeatNumbers : true) &&
    seatButtonStyle.fontSize >= minimumFontSizeToDisplay;

  const seatTitle = getSeatButtonTitle(
    content.seats,
    seat,
    seatStatusConfig?.status,
    seatCustomStyle,
    selectedLanguageCulture,
    areaProhibited,
    disableWheelchairSelection
  );

  const seatButtonOnClick = (
    e: React.MouseEvent | React.TouchEvent,
    seat: SeatMapSeat
  ) => {
    if (isSeatPreview) return;
    !isSeatSelectionDisabled && handleSeatClick(seat);
  };

  const seatTextStyles: ThemeUIStyleObject = {
    fontSize: `${seatButtonStyle.fontSize}px`,
    top: `${seatCustomStyle ? seatCustomStyle.seatNameYOffset : 0}px`,
    left: `${seatCustomStyle ? seatCustomStyle.seatNameXOffset : 0}px`,
    display: 'flex',
    textAlign: 'center',
  };

  const seatTextCssClass = classnames(
    'seat-id',
    seatStatusConfig?.cssClass == 'available' && 'available',
    seatStatusConfig?.cssClass == 'unavailable' && 'unavailable',
    seatStatusConfig?.cssClass == 'selected' && 'selected'
  );

  const svgContainerCssClass = classnames(
    'svg-container',
    seatConfig.type == SEAT_TYPE.SOFA_SEAT ? 'sofa' : '',
    seatConfig.svgClass,
    seatStatusConfig?.cssClass,
    areaProhibited && 'seat-prohibited'
  );

  const svgToUse =
    seat.status === SEAT_STATUS.BUFFER
      ? 'SeatBufferSvg'
      : seatCustomStyle
      ? 'Custom'
      : seatConfig.svgSrc;

  function calculateMargin(side: string) {
    const isSofaEdge =
      seat.style === SEAT_STYLE.SOFA_LEFT ||
      seat.style === SEAT_STYLE.SOFA_RIGHT;
    const halfMargin = config.seats.seatXMarginPct / 2;
    function getSofaEdgeMargin(side: string) {
      const sofaStyle =
        side === 'left' ? SEAT_STYLE.SOFA_LEFT : SEAT_STYLE.SOFA_RIGHT;
      const sofaEdgeMargin =
        config.seats.seatXMarginPct + (isThreeSeatSofa ? halfMargin : 0);
      const sofaEdgeInsideMargin = isThreeSeatSofa ? -halfMargin : 0;
      return seat.style === sofaStyle ? sofaEdgeMargin : sofaEdgeInsideMargin;
    }

    return isSofaEdge ? getSofaEdgeMargin(side) : halfMargin;
  }

  return (
    <div
      className={'seat-button-container'}
      style={{
        width: `${100 - config.seats.seatXMarginPct}%`,
        marginTop: `${config.seats.seatRowYMarginPct}%`,
        marginBottom: `${config.seats.seatRowYMarginPct}%`,
        marginLeft: `${calculateMargin('left')}%`,
        marginRight: `${calculateMargin('right')}%`,
      }}
    >
      <button
        aria-label={seatTitle}
        title={seatTitle}
        disabled={isSeatSelectionDisabled}
        aria-disabled={seatUnavailable}
        tabIndex={seatUnavailable ? -1 : 0}
        className={classnames(
          'seat-button',
          seatStatusConfig?.cssClass == 'available' && 'available',
          seatStatusConfig?.cssClass == 'unavailable' && 'unavailable',
          seatStatusConfig?.cssClass == 'selected' && 'selected',
          isSeatPreview && 'preview'
        )}
        sx={{ display: 'flex', alignItems: 'center' }}
        onClick={(e) => !isSeatSelectionDisabled && seatButtonOnClick(e, seat)}
      >
        {svgToUse &&
          resolveSvg(
            svgToUse,
            svgContainerCssClass,
            seatTitle,
            seatCustomStyle?.svg
          )}

        {shouldShowSeatText && (
          <div className={seatTextCssClass} sx={seatTextStyles}>
            {seat.text}
          </div>
        )}
      </button>
    </div>
  );
};

export default SeatButton;
