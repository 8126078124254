import React, { useEffect, useCallback } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Box } from 'theme-ui';

import { Config } from '../../../@types/configTypes';
import { Content } from '../../../@types/contentTypes';
import { DazzlerJourney } from '../../../@types/dazzlerTypes';
import { BookingData } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import journeyTypes from '../../../constants/journeyTypes';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectDazzlerConfig,
  selectError,
  selectSeatsModel,
} from '../../../store/Selectors';
import RichText from '../../common/richtext/RichText';
import SeatMapLayout from '../../common/seats/SeatMapLayout';
import SeatsContainer from '../../common/seats/SeatsContainer';
import DazzlerSeatMapLayout from '../../widgets/seatselection/SeatMapLayout';

const SeatPreview: React.FC = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { externalCinemaId, externalSessionId } = useParams();
  const seatsModel = useSelector(selectSeatsModel);
  const config = useSelector(selectConfig);
  const dazzlerConfig = useSelector(selectDazzlerConfig);
  const error = useSelector(selectError);

  const getBookingData = useCallback(async () => {
    dispatch(actionCreators.clearSession());
    dispatch(actionCreators.setLoading(true));
    const { preferredLanguageCulture } = cookies;
    const { search } = location;
    const source = (searchParams.get('source') ?? 'web').toLowerCase();
    const device = (searchParams.get('device') ?? '').toLowerCase();
    const circuitId = searchParams.get('circuitId') ?? undefined;
    const selectedLanguageCulture =
      preferredLanguageCulture ?? searchParams.get('culture');
    dispatch(actionCreators.setQueryString(search));

    const data = {
      circuitId,
      selectedLanguageCulture,
    };
    const url = `api/SeatPreview/${externalCinemaId}/${externalSessionId}`;
    const response = await backend.post(url, data);
    let bookingData: BookingData,
      config: Config,
      content: Content,
      dazzler: DazzlerJourney,
      dazzlerSteps: object | null,
      dazzlerContent: object | null,
      customer,
      result;
    result = response.content;
    if (response.ok) {
      bookingData = result.bookingData;
      delete result.circuit.config.cinemas;
      delete result.circuit.content.cinemas;
      config = result.circuit.config;
      content = result.circuit.content;
      dazzler = result.circuit?.dazzler;
      dazzlerSteps = result.circuit?.dazzlerSteps?.ticketing;
      dazzlerContent = result.circuit.dazzlerContent;

      const session = {
        loading: true,
        bookingData: bookingData,
        token: result.dataToken,
        config: config,
        content: content,
        dazzler: dazzler,
        dazzlerSteps: dazzlerSteps,
        dazzlerContent: dazzlerContent,
        cartSummary: result.cartSummaryModel,
        availablePosTickets: null,
        ticketTypes: null,
        bookingFee: 0,
        seatsModel: result.selectSeatsModel,
        singleSeatRuleFired: false,
        error: { show: false, message: '', peachCode: 0 },
        selectedSeats: [],
        giftCard: null,
        guestMarketing: null,
        countdownEndTime: null,
        countdownExpired: false,
        isUserValidated: false,
        journeyType: journeyTypes.SEAT_PREVIEW,
        kioskSubStep: 'fab',
        kioskActiveCategory: 0,
        customer: customer,
        source: source,
        device: device,
        step: 1,
        loyaltyRecognitionNumber: null,
        selectedConcessions: { list: [] },
        selectedGiftCards: { list: [] },
        concessions: null,
        selectedLanguageCulture: content.culture,
        gratuityLimitInCents: 0,
        bookingFeeStrategy: null,
        imageProcessorUrl: result.imageProcessorUrl,
        imageProcessorContainer: result.imageProcessorContainer,
        deals: null,
        isHostedPaymentInProgress: false,
        turnstileConfig: result.turnstileConfig,
        contentSecurityPolicyMetaTagContent:
          result.contentSecurityPolicyMetaTagContent,
        enableContentSecurityPolicy: result.enableContentSecurityPolicy,
        orderExists: false,
      };
      dispatch(actionCreators.initializeSession(session));
      if (result.peachCode !== PEACH_CODES.noError) {
        dispatch(
          actionCreators.setError(result.errorMessage, result.peachCode)
        );
      }
    } else {
      result = response.errorContent;
      config = result.circuit.config;
      content = result.circuit.content;

      dispatch(actionCreators.setCircuitConfig(config));
      dispatch(actionCreators.setCircuitContent(content));
      dispatch(actionCreators.setError(response.errorContent.errorMessage));
    }

    dispatch(actionCreators.setLoading(false));
  }, [
    cookies,
    dispatch,
    externalCinemaId,
    externalSessionId,
    location,
    searchParams,
  ]);

  useEffect(() => {
    if (externalCinemaId && externalSessionId) {
      getBookingData();
    }
  }, [externalCinemaId, externalSessionId, getBookingData]);

  const widget = dazzlerConfig.dazzler?.steps
    .find((s) => s.path === 'seats')
    ?.view?.widgets?.find(
      (w) => w.__typename === 'TicketingCMSJourneySeatSelectionWidget'
    );

  if (!config || !seatsModel) {
    return null;
  } else if (error.show) {
    return (
      <Box className='warning-container' sx={{ mt: 5, p: 5, mx: 3 }}>
        <RichText text={error.message} />
      </Box>
    );
  } else if (config && seatsModel) {
    return (
      <SeatsContainer>
        {widget ? (
          <DazzlerSeatMapLayout widget={widget} />
        ) : (
          <SeatMapLayout singleSeatRuleHasFired={false} />
        )}
      </SeatsContainer>
    );
  } else {
    return null;
  }
};

export default SeatPreview;
