import { defineMessages } from 'react-intl';

export default defineMessages({
  selectShowtimeText: {
    id: 'showtime-hero.select-showtime-text',
    defaultMessage: 'Update Showtime',
  },
  bookingRefLabel: {
    id: 'showtime-hero.booking-ref-label',
    defaultMessage: 'Booking reference:',
  },
  yourSeatsLabel: {
    id: 'showtime-hero.your-seats-label',
    defaultMessage: 'Your seats:',
  },
  showtimeChangeButtonAriaDescriptionText: {
    id: 'showtime-hero.showtime-change-button-aria-description-text',
    defaultMessage: 'Click to change the movie session to',
  },
  showtimeChangeDialogAriaLabelText: {
    id: 'showtime-hero.showtime-change-dialog-aria-label-text',
    defaultMessage: 'Showtime change dialog',
  },
  showtimeChangeDialogCloseButtonAriaLabelText: {
    id: 'showtime-hero.showtime-change-dialog-close-button-aria-label-text',
    defaultMessage: 'Close showtime change dialog',
  },
});
