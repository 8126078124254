/** @jsxImportSource theme-ui */
import React, { FC, memo } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ShowtimeHero from './ShowtimeHero';

import { ShowtimeHeroConfig } from '../../../@types/modelTypes';
import { BARCODE_FORMATS } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { selectConfig, selectConfirmationData } from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';

type Props = {
  showtimeHeroConfig: ShowtimeHeroConfig;
};

export const ShowtimeHeroConfirmation: FC<Props> = ({ showtimeHeroConfig }) => {
  const config = useSelector(selectConfig);
  const confirmationData = useSelector(selectConfirmationData);
  const { isLargeScreenWidth } = useScreenWidth();
  const { orderId } = useParams();

  if (!confirmationData || !config) return;

  const knownBarcodeFormat =
    BARCODE_FORMATS[config.currentCinema.barcodeFormat];
  const barcodeHeight = knownBarcodeFormat === 'QR' ? 150 : 60;
  const barcodeWidth = knownBarcodeFormat === 'QR' ? 150 : 264;
  const barcodeUrl = `/Image/GetBarcode/${confirmationData.externalOrderId}/${barcodeHeight}/${barcodeWidth}?circuitId=${config.circuitId}`;
  const shouldShowBarcode =
    knownBarcodeFormat !== 'None' && knownBarcodeFormat !== undefined;
  const showSummaryInShowtimeHero = !isLargeScreenWidth;

  const shouldShowConfirmationNumber =
    config.currentCinema.showConfirmationId &&
    confirmationData.bookingConfirmationId !==
      confirmationData.codeUsedInBarcode &&
    !!confirmationData.bookingConfirmationId;

  return (
    <ContainedRow>
      <ShowtimeHero
        barcodeUrl={barcodeUrl}
        displayAddToCalendar={showSummaryInShowtimeHero}
        displayConfirmationNumber={shouldShowConfirmationNumber}
        displayBarcode={shouldShowBarcode && showSummaryInShowtimeHero}
        displayMobileWallets={showSummaryInShowtimeHero}
        orderId={orderId}
        showtimeHeroConfig={showtimeHeroConfig}
      />
    </ContainedRow>
  );
};

export default memo(ShowtimeHeroConfirmation);
