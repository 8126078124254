import React, { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import messages from './intl';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  GlobalState,
  TicketTypeModel,
  TicketTypes,
  ValidatedCardPaymentPromoBankCard,
  ValidatedMemberCard,
} from '../../../@types/modelTypes';
import {
  AnalyticsTrackingEventProperties,
  TrackingEvent,
} from '../../../@types/trackingTypes';
import { useAnalytics } from '../../../analytics/analyticsContext';
import { useSetBookingFeeAndTax } from '../../../hooks/useSetBookingFeeAndTax';
import {
  getOrderedTicketGroupsDazzler,
  setDeals,
  updateJourneyType,
} from '../../../services/TicketHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectCartSummary,
  selectTicketTypes,
  selectDeals,
  selectDealsInTicketsStep,
  selectJourneyTypeConfig,
  selectState,
  selectUseDynamicTicket,
  selectAppliedMemberCards,
  selectBookingData,
  selectIsSeatsFirstJourney,
  selectAppliedCardPaymentPromoBankCards,
} from '../../../store/Selectors';
import { WidgetData } from '../types';

interface Props {
  orderHasMaxTickets: boolean;
  ticketTypeGroup:
    | 'members-only'
    | 'non-member-tickets'
    | 'all-tickets'
    | 'card-promo';
  setShowRegister?: React.Dispatch<React.SetStateAction<boolean>>;
  widget: WidgetData<'TicketingCMSJourneyTicketSelectionWidget'>;
  noTopMargin?: boolean;
}

const TicketSelectorContainer: React.FC<Props> = ({
  orderHasMaxTickets,
  ticketTypeGroup,
  setShowRegister,
  widget,
  noTopMargin,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { formatMessage } = useIntl();

  const globalState = useSelector(selectState);
  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);

  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const tickets = useSelector(
    (state: GlobalState) => state.availablePosTickets
  );
  const ticketTypes = useSelector(selectTicketTypes);
  const deals = useSelector(selectDeals) ?? [];
  const dealsInTicketsStep = useSelector(selectDealsInTicketsStep) ?? [];
  const useDynamicTicket = useSelector(selectUseDynamicTicket);
  const appliedMemberCards: ValidatedMemberCard[] = useSelector(
    selectAppliedMemberCards
  );
  const appliedCardPaymentPromoBankCards: ValidatedCardPaymentPromoBankCard[] =
    useSelector(selectAppliedCardPaymentPromoBankCards);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  useSetBookingFeeAndTax();

  useEffect(() => {
    if (ticketTypes || !tickets) return;
    const createTicketsList = () => {
      const { groupedTicketTypes } = tickets;
      const ticketTypeModels: TicketTypeModel[] = [];
      groupedTicketTypes.forEach((x) => {
        x.ticketTypeModels.forEach((m) => {
          ticketTypeModels.push(m);
        });
      });
      const ticketTypes = { ticketTypeModels };
      dispatch(actionCreators.setTicketTypes(ticketTypes));
    };
    createTicketsList();
  }, [ticketTypes, tickets, dispatch]);

  if (!ticketTypes || !cartSummary) return null;

  const handleTicketButtonClick = async (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => {
    const { ticketTypeModels } = ticketTypes;
    const ticketTypeModel = ticketTypeModels.find((x) => x.id === ticketTypeId);
    if (!ticketTypeModel) {
      return;
    }
    const shouldShowRegister =
      setShowRegister &&
      widget.shape?.enableMultipleLoyaltyCardTicketPurchase &&
      !globalState.bookingData.isUserValidated &&
      context === 'add';

    const hasDeals = !!deals && deals.length;
    if (shouldShowRegister) {
      setShowRegister(true);
      return;
    }

    const tryRemoveMemberTicket = () => {
      const shouldRemoveMemberTicket =
        widget.shape?.enableMultipleLoyaltyCardTicketPurchase &&
        bookingData.isUserValidated &&
        ticketTypeModel.isMemberTicket;
      if (shouldRemoveMemberTicket) {
        const appliedMemberCardsWithSameTicketId = appliedMemberCards
          .filter((x) => x.usedTicket.id === ticketTypeId)
          .map((x) => ({ ...x }));

        if (
          appliedMemberCardsWithSameTicketId?.length > 0 &&
          (ticketTypeModel.quantity === 2 ||
            ticketTypeModel.quantity ===
              appliedMemberCardsWithSameTicketId.length)
        ) {
          const lastUsedMemberCardNumber =
            appliedMemberCardsWithSameTicketId[
              appliedMemberCardsWithSameTicketId.length - 1
            ]?.memberCardNumber;

          if (lastUsedMemberCardNumber) {
            if (ticketTypeModel.validatedVouchers) {
              ticketTypeModel.validatedVouchers =
                ticketTypeModel.validatedVouchers.filter(
                  (card) => card !== lastUsedMemberCardNumber
                );
              dispatch(
                actionCreators.removeMemberCard(lastUsedMemberCardNumber)
              );
            }
          }
        }
      }
    };

    const tryRemovePromoTicket = () => {
      const appliedBankCardToRemove = appliedCardPaymentPromoBankCards.find(
        (c) => c.usedTicketId === ticketTypeId
      );

      appliedBankCardToRemove &&
        dispatch(
          actionCreators.removeCardPaymentPromoBankCard(
            appliedBankCardToRemove.bankCardNumber
          )
        );
    };

    if (context === 'add') {
      ticketTypeModel.quantity += 1;
      const trackProperties: AnalyticsTrackingEventProperties = {
        globalState,
      };
      analytics?.track(TrackingEvent.ADD_TICKET, trackProperties);
      useDynamicTicket &&
        dispatch(actionCreators.addSingleTicket(ticketTypeModel));
    } else if (context === 'remove') {
      ticketTypeModel.isCardPaymentPromotionTicket && tryRemovePromoTicket();
      tryRemoveMemberTicket();
      ticketTypeModel.quantity += -1;
      if (!isSeatsFirstJourney) {
        dispatch(actionCreators.removeAllSeats());
      }
      useDynamicTicket &&
        dispatch(actionCreators.removeSingleTicket(ticketTypeModel));
    }

    const nextTicketTypes: TicketTypes = { ticketTypeModels };
    dispatch(actionCreators.setTicketTypes(nextTicketTypes));

    if (widget.shape?.useAllocatableSeatingToUpdateJourneyType) {
      updateJourneyType(tickets, journeyTypeConfig.type, dispatch);
    }

    if (hasDeals) {
      setDeals(
        ticketTypeId,
        nextTicketTypes,
        deals,
        dealsInTicketsStep,
        context,
        dispatch
      );
    }
  };

  const memberTickets =
    ticketTypeGroup === 'non-member-tickets'
      ? []
      : ticketTypes.ticketTypeModels.filter(
          (t) => t.isMemberTicket && !t.isCardPaymentPromotionTicket
        );

  const packageTickets =
    ticketTypeGroup === 'members-only'
      ? []
      : ticketTypes.ticketTypeModels.filter(
          (t) =>
            t.isPackageTicket &&
            !t.isMemberTicket &&
            !t.isCardPaymentPromotionTicket
        );

  const standardTickets =
    ticketTypeGroup === 'members-only'
      ? []
      : ticketTypes.ticketTypeModels.filter(
          (t) =>
            !t.isMemberTicket &&
            !t.isPackageTicket &&
            !t.isCardPaymentPromotionTicket
        );

  const cardPromoTickets =
    ticketTypeGroup === 'card-promo'
      ? ticketTypes.ticketTypeModels.filter(
          (t) => t.isCardPaymentPromotionTicket
        )
      : [];

  const numberOfSections = () => {
    let count = 0;
    if (
      memberTickets.length > 0 &&
      formatMessage(messages.memberTicketsHeading)
    )
      count += 1;
    if (
      packageTickets.length > 0 &&
      formatMessage(messages.packageTicketsHeading)
    )
      count += 1;
    if (
      standardTickets.length > 0 &&
      formatMessage(messages.standardTicketsHeading)
    )
      count += 1;
    return count;
  };

  const showHeadings = numberOfSections() > 1;
  let margin = 7;
  if (noTopMargin) {
    margin = 0;
  } else if (ticketTypeGroup === 'card-promo' || showHeadings) {
    margin = 4;
  }
  const className =
    ticketTypeGroup === 'members-only' ? 'multiple-member-ticket' : 'contained';

  const getOrderedTicketsVars = {
    enableMultipleLoyaltyCardTicketPurchase:
      widget.shape?.enableMultipleLoyaltyCardTicketPurchase || false,
    enableMemberTicketsHighlight:
      widget.shape?.enableMemberTicketsHighlight || false,
    enablePackageTicketsHighlight:
      widget.shape?.enablePackageTicketsHighlight || false,
    ticketGroupsOrderStrategy:
      widget.shape?.ticketGroupsOrderStrategy ?? 'MEMBER_PACKAGE_STANDARD',
    cardPaymentPromoTicketsHeading: formatMessage(
      messages.cardPaymentPromoTicketsHeading
    ),
    memberTicketsHeading: formatMessage(messages.memberTicketsHeading),
    packageTicketsHeading: formatMessage(messages.packageTicketsHeading),
    standardTicketsHeading: formatMessage(messages.standardTicketsHeading),
    showHeadings,
  };
  return (
    <Box className={className} sx={{ mt: margin }}>
      {getOrderedTicketGroupsDazzler(
        getOrderedTicketsVars,
        orderHasMaxTickets,
        memberTickets,
        packageTickets,
        standardTickets,
        cardPromoTickets,
        handleTicketButtonClick
      )}
    </Box>
  );
};

export default TicketSelectorContainer;
