export default {
  SEAT_FIRST: 'SEAT_FIRST',
  SEAT_FIRST_KIOSK: 'SEAT_FIRST_KIOSK',
  TICKET_FIRST: 'TICKET_FIRST',
  TICKET_FIRST_KIOSK: 'TICKET_FIRST_KIOSK',
  TICKETS_ONLY: 'TICKETS_ONLY',
  TICKETS_KIOSK: 'TICKETS_KIOSK',
  CONCESSIONS_ONLY: 'CONCESSIONS_ONLY',
  GIFTCARDS_ONLY: 'GIFTCARDS_ONLY',
  VOUCHERS_ONLY: 'VOUCHERS_ONLY',
  PHYSICAL_GIFTCARDS_ONLY: 'PHYSICAL_GIFTCARDS_ONLY',
  GIFTMEMBERSHIP_ONLY: 'GIFTMEMBERSHIP_ONLY',
  LOYALTY_UPGRADE: 'LOYALTY_UPGRADE',
  LOYALTY_SIGNUP_FREE: 'LOYALTY_SIGNUP_FREE',
  LOYALTY_SIGNUP_TIERED: 'LOYALTY_SIGNUP_TIERED',
  SEASON_PASS: 'SEASON_PASS',
  SEAT_PREVIEW: 'SEAT_PREVIEW',
};
