/** @jsxImportSource theme-ui */
import React from 'react';

import { ReactComponent as ZoomInSvg } from '../../../svgs/zoomIn.svg';
import { ReactComponent as ZoomOutSvg } from '../../../svgs/zoomOut.svg';

interface Props {
  buttonStyle: 'zoomIn' | 'zoomOut';
  onClick: React.MouseEventHandler;
  title: string;
}

const ZoomButton: React.FC<Props> = ({ buttonStyle, onClick, title }) => {
  return (
    <div
      data-testid='zoom-button'
      onClick={onClick}
      title={title}
      className='zoom-button'
      sx={{
        backgroundColor: 'websiteBackground',
        '.zoom-svg-container': {
          '.zoom-container, .zoom-icon': {
            fill: 'mostReadableOnWebsiteBackground',
          },
        },
        '&:hover': {
          '.zoom-container, .zoom-icon': {
            fill: 'primary',
          },
        },
      }}
      aria-hidden='true'
    >
      {buttonStyle === 'zoomIn' ? (
        <ZoomInSvg className='zoom-svg-container' />
      ) : (
        <ZoomOutSvg className='zoom-svg-container' />
      )}
    </div>
  );
};

export default ZoomButton;
