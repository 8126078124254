import React from 'react';

import { useSelector } from 'react-redux';
import { Grid } from 'theme-ui';

import { selectDazzlerConfig } from '../../store/Selectors';
import Widget from '../widgets';
interface Props {
  step: string;
}

const DazzlerWrapper: React.FC<Props> = ({ step }) => {
  const dazzlerConfig = useSelector(selectDazzlerConfig);

  if (!dazzlerConfig?.dazzlerSteps?.includes(step) || !dazzlerConfig.dazzler) {
    return null;
  }
  const thisStep = dazzlerConfig.dazzler?.steps.find((s) => s.path === step);
  const view = thisStep?.view;
  const widgets = view?.widgets;

  if (!widgets || widgets.length == 0) {
    return (
      <div>This journey step is configured but not enabled in the CMS</div>
    );
  }

  return (
    <Grid gap={4} data-testid={step}>
      {widgets?.map((widget) => {
        return <Widget key={widget.id} widget={widget} step={step} />;
      })}
    </Grid>
  );
};

export default DazzlerWrapper;
